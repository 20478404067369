/*------------------------------------------------------------------

[Master Stylesheet]

Project        :   Hostma - Modern Web Hosting Template
Copyright      :   Spruko Technologies Private Limited 
Author         :   Spruko
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@{$LANG.$sld_key}.{$LANG.$tld_key}spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=block");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@500;600&display=block");
:root {
  --body-bg-rgb:	255, 255, 255;
  --primary-rgb:	20, 87, 230;
  --secondary-rgb:	247, 131, 38;
  --warning-rgb:	245, 177, 75;
  --info-rgb:	78, 194, 240;
  --success-rgb:	23, 202, 104;
  --danger-rgb:	236, 95, 103;
  --pink-rgb: 227, 74, 230;
  --blue-rgb: 38, 143, 230;
  --light-rgb:	253, 253, 254;
  --dark-rgb:	25, 31, 43;
  --orange-rgb:	253, 126, 20;
  --teal-rgb:	74, 201, 189;
  --purple-rgb:	120, 145, 239;
  --default-body-bg-color:	rgb(var(--body-bg-rgb));
  --primary-color:	rgb(var(--primary-rgb));
  --primary-hover:	rgba(var(--primary-rgb), 0.8);
  --primary-border:	rgb(var(--primary-rgb));
  --primary005:	rgba(var(--primary-rgb), 0.05);
  --primary01:	rgba(var(--primary-rgb), 0.1);
  --primary02:	rgba(var(--primary-rgb), 0.2);
  --primary03:	rgba(var(--primary-rgb), 0.3);
  --primary04:	rgba(var(--primary-rgb), 0.4);
  --primary05:	rgba(var(--primary-rgb), 0.5);
  --primary06:	rgba(var(--primary-rgb), 0.6);
  --primary07:	rgba(var(--primary-rgb), 0.7);
  --primary08:	rgba(var(--primary-rgb), 0.8);
  --primary09:	rgba(var(--primary-rgb), 0.9);
  --default-text-color:	#6b6b7e;
  --color-dark: #03123e;
  --border: #e9e9e9;
  --shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.1);
  --default-background:	#ebebeb;
  --custom-white:	#fff;
  --custom-black:	#000;
  --muted: #7987a1;
  --menu-color:	#6b6b7e;
  --border-width:	1px;
  /* Gray set */
  --gray-100: #f9fafb;
  --gray-200: #f2f4f5;
  --gray-300: #e6eaeb;
  --gray-400: #dbdfe1;
  --gray-500: #949eb7;
  --gray-600: #7987a1;
  --gray-700: #4d5875;
  --gray-800: #383853;
  --gray-900: #323251;
  /* White set */
  --white-1:	rgba(255, 255, 255, 0.1);
  --white-2:	rgba(255, 255, 255, 0.2);
  --white-3:	rgba(255, 255, 255, 0.3);
  --white-4:	rgba(255, 255, 255, 0.4);
  --white-5:	rgba(255, 255, 255, 0.5);
  --white-6:	rgba(255, 255, 255, 0.6);
  --white-7:	rgba(255, 255, 255, 0.7);
  --white-8:	rgba(255, 255, 255, 0.8);
  --white-9:	rgba(255, 255, 255, 0.9);
  --white-05:	rgba(255, 255, 255, 0.05);
  --white-07:	rgba(255, 255, 255, 0.07);
  --white-75:	rgba(255, 255, 255, 0.075);
  --white-08:	rgba(255, 255, 255, 0.08);
  /* Black set */
  --black-1:	rgba(0, 0, 0, 0.1);
  --black-2:	rgba(0, 0, 0, 0.2);
  --black-3:	rgba(0, 0, 0, 0.3);
  --black-4:	rgba(0, 0, 0, 0.4);
  --black-5:	rgba(0, 0, 0, 0.5);
  --black-6:	rgba(0, 0, 0, 0.6);
  --black-7:	rgba(0, 0, 0, 0.7);
  --black-8:	rgba(0, 0, 0, 0.8);
  --black-9:	rgba(0, 0, 0, 0.9);
  --black-03:	rgba(0, 0, 0, 0.03);
  --black-05:	rgba(0, 0, 0, 0.05);
  /* Float */
  --float-start: left;
  --float-end: right;
}

/*Body & Text colors*/
/*Default Background Color*/
/*Border Color*/
/*Shadow*/
/*Border-width*/
/*menu variables*/
/*Color Variables*/
/*gradient variables*/
/* shadow variables */
/*gray set*/
/*white set*/
/*black set*/
/* dark mode */
[data-theme-mode=dark] {
  --body-bg-rgb:	23, 38, 58;
  --custom-white:	rgb(var(--body-bg-rgb));
  --custom-black:	#fff;
  --border: rgba(255, 255, 255, 0.1);
  --default-text-color:	rgba(255, 255, 255, 0.7);
  --color-dark: #fff;
  --dark-rgb:	40 ,40 ,40;
  --default-background:	rgba(255, 255, 255, 0.1);
  --default-body-bg-color:	rgb(var(--body-bg-rgb), 0.95);
  --muted: rgba(255, 255, 255, 0.4);
  --light-rgb:	255, 255, 255, 0.1;
  --menu-color:	255, 255, 255;
  /* Gray Set */
  --gray-100:	rgba(255, 255, 255, 0.02);
  --gray-200:	rgba(255, 255, 255, 0.05);
  --gray-300:	rgba(255, 255, 255, 0.1);
  --gray-400:	rgba(255, 255, 255, 0.15);
  --gray-500:	rgba(255, 255, 255, 0.2);
  --gray-600:	rgba(255, 255, 255, 0.25);
  --gray-700:	rgba(255, 255, 255, 0.3);
  --gray-800:	rgba(255, 255, 255, 0.35);
  --gray-900:	rgba(255, 255, 255, 0.4);
  /* white set */
  --white-1:	rgba(0, 0, 0, 0.1);
  --white-2:	rgba(0, 0, 0, 0.2);
  --white-3:	rgba(0, 0, 0, 0.3);
  --white-4:	rgba(0, 0, 0, 0.4);
  --white-5:	rgba(0, 0, 0, 0.5);
  --white-6:	rgba(0, 0, 0, 0.6);
  --white-7:	rgba(0, 0, 0, 0.7);
  --white-8:	rgba(0, 0, 0, 0.8);
  --white-9:	rgba(0, 0, 0, 0.9);
  --white-05:	rgba(0, 0, 0, 0.05);
  --white-07:	rgba(0, 0, 0, 0.07);
  --white-75:	rgba(0, 0, 0, 0.075);
  --white-08:	rgba(0, 0, 0, 0.08);
  /* black set */
  --black-1:	rgba(255, 255, 255, 0.1);
  --black-2:	rgba(255, 255, 255, 0.2);
  --black-3:	rgba(255, 255, 255, 0.3);
  --black-4:	rgba(255, 255, 255, 0.4);
  --black-5:	rgba(255, 255, 255, 0.5);
  --black-6:	rgba(255, 255, 255, 0.6);
  --black-7:	rgba(255, 255, 255, 0.7);
  --black-8:	rgba(255, 255, 255, 0.8);
  --black-9:	rgba(255, 255, 255, 0.9);
  --black-03:	rgba(255, 255, 255, 0.03);
  --black-05:	rgba(255, 255, 255, 0.05);
}

@media (prefers-color-scheme: dark) {
  [data-theme-mode=dark] {
    --body-bg-rgb:	23, 38, 58;
    --custom-white:	rgb(var(--body-bg-rgb));
    --custom-black:	#fff;
    --border: rgba(255, 255, 255, 0.1);
    --default-text-color:	rgba(255, 255, 255, 0.7);
    --dark-rgb:	20 ,20 ,20;
    --default-background:	rgba(255, 255, 255, 0.1);
    --default-body-bg-color:	rgb(var(--body-bg-rgb), 0.95);
    --muted: rgba(255, 255, 255, 0.4);
    --light-rgb:	255, 255, 255, 0.1;
    --menu-color:	255, 255, 255;
    /* Gray Set */
    --gray-100:	rgba(255, 255, 255, 0.02);
    --gray-200:	rgba(255, 255, 255, 0.05);
    --gray-300:	rgba(255, 255, 255, 0.1);
    --gray-400:	rgba(255, 255, 255, 0.15);
    --gray-500:	rgba(255, 255, 255, 0.2);
    --gray-600:	rgba(255, 255, 255, 0.25);
    --gray-700:	rgba(255, 255, 255, 0.3);
    --gray-800:	rgba(255, 255, 255, 0.35);
    --gray-900:	rgba(255, 255, 255, 0.4);
    /* white set */
    --white-1:	rgba(0, 0, 0, 0.1);
    --white-2:	rgba(0, 0, 0, 0.2);
    --white-3:	rgba(0, 0, 0, 0.3);
    --white-4:	rgba(0, 0, 0, 0.4);
    --white-5:	rgba(0, 0, 0, 0.5);
    --white-6:	rgba(0, 0, 0, 0.6);
    --white-7:	rgba(0, 0, 0, 0.7);
    --white-8:	rgba(0, 0, 0, 0.8);
    --white-9:	rgba(0, 0, 0, 0.9);
    --white-05:	rgba(0, 0, 0, 0.05);
    --white-07:	rgba(0, 0, 0, 0.07);
    --white-75:	rgba(0, 0, 0, 0.075);
    --white-08:	rgba(0, 0, 0, 0.08);
    /* black set */
    --black-1:	rgba(255, 255, 255, 0.1);
    --black-2:	rgba(255, 255, 255, 0.2);
    --black-3:	rgba(255, 255, 255, 0.3);
    --black-4:	rgba(255, 255, 255, 0.4);
    --black-5:	rgba(255, 255, 255, 0.5);
    --black-6:	rgba(255, 255, 255, 0.6);
    --black-7:	rgba(255, 255, 255, 0.7);
    --black-8:	rgba(255, 255, 255, 0.8);
    --black-9:	rgba(255, 255, 255, 0.9);
    --black-03:	rgba(255, 255, 255, 0.03);
    --black-05:	rgba(255, 255, 255, 0.05);
  }
}
[dir=rtl] {
  --float-start: right;
  --float-end: left;
}

/* ######## FONT ######## */
/* ######## BOOTSTRAP ######## */
/* Start::button default */
.btn {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 0.3rem;
}

.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  color: #fff;
}

/* End::button default */
/* Start::btn-primary */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-primary:hover, .btn-primary:focus:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-border);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-border);
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary.show:focus,
.btn-primary:active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:active {
  background-color: var(--primary-color);
  border-color: var(--primary-border);
}

/* End::btn-primary */
/* Start::btn-secondary */
.btn-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
}
.btn-secondary:hover, .btn-secondary:focus:hover {
  background-color: rgba(var(--secondary-rgb), 0.9);
  border-color: rgb(var(--secondary-rgb));
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary.show:focus,
.btn-secondary:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary.show,
.btn-secondary:active {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

/* End::btn-secondary */
/* Start::btn-success */
.btn-success {
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
}
.btn-success:hover, .btn-success:focus:hover {
  background-color: rgba(var(--success-rgb), 0.9);
  border-color: rgb(var(--success-rgb));
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success.show:focus,
.btn-success:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5);
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success.show,
.btn-success:active {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}

/* End::btn-success */
/* Start::btn-danger */
.btn-danger {
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
}
.btn-danger:hover, .btn-danger:focus:hover {
  background-color: rgba(var(--danger-rgb), 0.9);
  border-color: rgb(var(--danger-rgb));
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger.show:focus,
.btn-danger:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5);
}

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger.show,
.btn-danger:active {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}

/* End::btn-danger */
/* Start::btn-light */
.btn-light {
  background-color: var(--gray-300);
  border-color: var(--gray-300);
  color: var(--default-text-color);
}
.btn-light:hover, .btn-light:focus:hover {
  background-color: var(--gray-200);
  border-color: var(--gray-300);
}

.btn-pink {
  background-color: rgb(var(--pink-rgb));
  border-color: rgb(var(--pink-rgb));
  color: #fff;
}
.btn-pink:hover, .btn-pink:focus:hover {
  background-color: rgba(var(--pink-rgb), 0.9);
  border-color: rgb(var(--pink-rgb));
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  background-color: var(--gray-300);
  border-color: var(--gray-300);
  color: var(--default-text-color);
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light.show:focus,
.btn-light:active:focus {
  box-shadow: 0 0 0 0.2rem var(--gray-300);
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light.show,
.btn-light:active {
  background-color: var(--gray-300);
  border-color: var(--gray-300);
  color: var(--default-text-color);
}

/* End::btn-light */
/* Start::btn-white */
.btn-white {
  background-color: #fff;
  border-color: #fff;
  color: #4d5875;
}
.btn-white:hover, .btn-white:focus:hover {
  background-color: #fff;
  border-color: #fff;
  color: #383853;
}

.btn-check:focus + .btn-white,
.btn-white:focus {
  background-color: #fff;
  border-color: #fff;
  color: #4d5875;
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-white:focus,
.btn-check:checked + .btn-white:focus,
.btn-white.active:focus,
.btn-white.show:focus,
.btn-white:active:focus {
  box-shadow: 0 0 0 0.2rem var(--white-05);
}

.btn-check:active + .btn-white,
.btn-check:checked + .btn-white,
.btn-white.active,
.btn-white.show,
.btn-white:active {
  background-color: #fff;
  border-color: #fff;
}

/* End::btn-white */
/* Start::btn-primary-transparent */
.btn-primary-transparent {
  background-color: var(--primary02);
  border-color: var(--primary02);
  color: var(--primary-color);
}
.btn-primary-transparent:hover, .btn-primary-transparent:focus:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.btn-check:focus + .btn-primary-transparent,
.btn-primary-transparent:focus {
  background-color: var(--primary02);
  border-color: var(--primary02);
  outline: 0;
  box-shadow: none;
  color: var(--primary-color);
}

.btn-check:active + .btn-primary-transparent:focus,
.btn-check:checked + .btn-primary-transparent:focus,
.btn-primary-transparent.active:focus,
.btn-primary-transparent.show:focus,
.btn-primary-transparent:active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.btn-check:active + .btn-primary-transparent,
.btn-check:checked + .btn-primary-transparent,
.btn-primary-transparent.active,
.btn-primary-transparent.show,
.btn-primary-transparent:active {
  background-color: var(--primary-color);
  border-color: var(--primary02);
  color: #fff;
}

/* End::btn-primary-transparent */
/* Start::btn-secondary-transparent */
.btn-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.2);
  border-color: rgba(var(--secondary-rgb), 0.2);
  color: rgb(var(--secondary-rgb));
}
.btn-secondary-transparent:hover, .btn-secondary-transparent:focus:hover {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.btn-check:focus + .btn-secondary-transparent,
.btn-secondary-transparent:focus {
  background-color: rgba(var(--secondary-rgb), 0.2);
  border-color: rgba(var(--secondary-rgb), 0.2);
  outline: 0;
  box-shadow: none;
  color: rgb(var(--secondary-rgb));
}

.btn-check:active + .btn-secondary-transparent:focus,
.btn-check:checked + .btn-secondary-transparent:focus,
.btn-secondary-transparent.active:focus,
.btn-secondary-transparent.show:focus,
.btn-secondary-transparent:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:active + .btn-secondary-transparent,
.btn-check:checked + .btn-secondary-transparent,
.btn-secondary-transparent.active,
.btn-secondary-transparent.show,
.btn-secondary-transparent:active {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgba(var(--secondary-rgb), 0.2);
  color: #fff;
}

/* End::btn-secondary-transparent */
/* Start::btn-success-transparent */
.btn-success-transparent {
  background-color: rgba(var(--success-rgb), 0.2);
  border-color: rgba(var(--success-rgb), 0.2);
  color: rgb(var(--success-rgb));
}
.btn-success-transparent:hover, .btn-success-transparent:focus:hover {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  color: #fff;
}

.btn-check:focus + .btn-success-transparent,
.btn-success-transparent:focus {
  background-color: rgba(var(--success-rgb), 0.2);
  border-color: rgba(var(--success-rgb), 0.2);
  outline: 0;
  box-shadow: none;
  color: rgb(var(--success-rgb));
}

.btn-check:active + .btn-success-transparent:focus,
.btn-check:checked + .btn-success-transparent:focus,
.btn-success-transparent.active:focus,
.btn-success-transparent.show:focus,
.btn-success-transparent:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5);
}

.btn-check:active + .btn-success-transparent,
.btn-check:checked + .btn-success-transparent,
.btn-success-transparent.active,
.btn-success-transparent.show,
.btn-success-transparent:active {
  background-color: rgb(var(--success-rgb));
  border-color: rgba(var(--success-rgb), 0.2);
  color: #fff;
}

/* End::btn-success-transparent */
/* Start::btn-danger-transparent */
.btn-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.2);
  border-color: rgba(var(--danger-rgb), 0.2);
  color: rgb(var(--danger-rgb));
}
.btn-danger-transparent:hover, .btn-danger-transparent:focus:hover {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  color: #fff;
}

.btn-check:focus + .btn-danger-transparent,
.btn-danger-transparent:focus {
  background-color: rgba(var(--danger-rgb), 0.2);
  border-color: rgba(var(--danger-rgb), 0.2);
  outline: 0;
  box-shadow: none;
  color: rgb(var(--danger-rgb));
}

.btn-check:active + .btn-danger-transparent:focus,
.btn-check:checked + .btn-danger-transparent:focus,
.btn-danger-transparent.active:focus,
.btn-danger-transparent.show:focus,
.btn-danger-transparent:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5);
}

.btn-check:active + .btn-danger-transparent,
.btn-check:checked + .btn-danger-transparent,
.btn-danger-transparent.active,
.btn-danger-transparent.show,
.btn-danger-transparent:active {
  background-color: rgb(var(--danger-rgb));
  border-color: rgba(var(--danger-rgb), 0.2);
  color: #fff;
}

/* End::btn-danger-transparent */
/* Start::btn-outline-primary */
.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-outline-primary:hover, .btn-outline-primary:focus:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  background-color: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
  box-shadow: none;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.show:focus,
.btn-outline-primary:active:focus {
  color: #fff;
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.show,
.btn-outline-primary:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

/* End::btn-outline-primary */
/* Start::btn-outline-secondary */
.btn-outline-secondary {
  color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus:hover {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  background-color: transparent;
  border-color: rgb(var(--secondary-rgb));
  color: rgb(var(--secondary-rgb));
  box-shadow: none;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.show:focus,
.btn-outline-secondary:active:focus {
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.show,
.btn-outline-secondary:active {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

/* End::btn-outline-secondary */
/* Start::btn-outline-success */
.btn-outline-success {
  color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}
.btn-outline-success:hover, .btn-outline-success:focus:hover {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  color: #fff;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  background-color: transparent;
  border-color: rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
  box-shadow: none;
}

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.show:focus,
.btn-outline-success:active:focus {
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.show,
.btn-outline-success:active {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}

/* End::btn-outline-success */
/* Start::btn-outline-danger */
.btn-outline-danger {
  color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}
.btn-outline-danger:hover, .btn-outline-danger:focus:hover {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  color: #fff;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  background-color: transparent;
  border-color: rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
  box-shadow: none;
}

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.show:focus,
.btn-outline-danger:active:focus {
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5);
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.show,
.btn-outline-danger:active {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}

/* End::btn-outline-danger */
/* Start::btn-sm|btn-group-sm */
.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  border-radius: 0.25rem;
}

/* Start::btn-sm|btn-group-sm */
/* Start::btn-lg|btn-group-lg */
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.69rem 1.2rem;
  font-size: 1rem;
}

/* Start::btn-lg|btn-group-lg */
/* Start::btn-icon */
.btn-icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--primary01);
}

/* End::btn-icon */
/* Start::btn-close */
.btn-close:focus {
  box-shadow: none;
}

.btn-info-dark {
  background-color: #43abfc;
  border-color: #43abfc;
  color: #fff;
}
.btn-info-dark:hover, .btn-info-dark.active {
  background-color: #43abfc;
  border-color: #43abfc;
  color: #fff;
  opacity: 0.9;
}

.btn-check:active + .btn.btn-info-dark,
.btn-check:checked + .btn.btn-info-dark,
.btn.btn-info-dark.active,
.btn.btn-info-dark.show,
.btn.btn-info-dark:active {
  background-color: #43abfc;
  border-color: #43abfc;
  color: #fff;
  opacity: 0.9;
}

.btn.btn-info-dark:focus {
  background-color: #43abfc;
  border-color: #43abfc;
  color: #fff;
  opacity: 0.9;
}

/* End::btn-close */
/* Start::btn-secondary-gradient */
.btn-primary-gradient {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
  border-color: var(--primary-color);
  color: #fff;
}
.btn-primary-gradient:hover, .btn-primary-gradient:focus:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.btn-check:focus + .btn-primary-gradient,
.btn-primary-gradient:focus {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: none;
  color: #fff;
}

.btn-check:active + .btn-primary-gradient:focus,
.btn-check:checked + .btn-primary-gradient:focus,
.btn-primary-gradient.active:focus,
.btn-primary-gradient.show:focus,
.btn-primary-gradient:active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.btn-check:active + .btn-primary-gradient,
.btn-check:checked + .btn-primary-gradient,
.btn-primary-gradient.active,
.btn-primary-gradient.show,
.btn-primary-gradient:active {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
  border-color: var(--primary-color);
  color: #fff;
}

/* End::btn-secondary-gradient */
/* Start::btn-secondary-gradient */
.btn-secondary-gradient {
  background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, rgba(var(--secondary-rgb), 0.5) 100%);
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.btn-secondary-gradient:hover, .btn-secondary-gradient:focus:hover {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.btn-check:focus + .btn-secondary-gradient,
.btn-secondary-gradient:focus {
  background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, rgba(var(--secondary-rgb), 0.5) 100%);
  border-color: rgb(var(--secondary-rgb));
  outline: 0;
  box-shadow: none;
  color: rgb(var(--secondary-rgb));
}

.btn-check:active + .btn-secondary-gradient:focus,
.btn-check:checked + .btn-secondary-gradient:focus,
.btn-secondary-gradient.active:focus,
.btn-secondary-gradient.show:focus,
.btn-secondary-gradient:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:active + .btn-secondary-gradient,
.btn-check:checked + .btn-secondary-gradient,
.btn-secondary-gradient.active,
.btn-secondary-gradient.show,
.btn-secondary-gradient:active {
  background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, rgba(var(--secondary-rgb), 0.5) 100%);
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}

/* End::btn-secondary-gradient */
/* Start::btn-primary-transparent */
.btn-primary-transparent {
  background-color: var(--primary02);
  color: var(--primary-color);
}
.btn-primary-transparent:hover {
  background-color: var(--primary-color);
  color: #fff;
}

/* End::btn-primary-transparent */
/* Start::btn-danger-transparent */
.btn-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.2);
  border-color: rgba(var(--danger-rgb), 0.2);
  color: rgb(var(--danger-rgb));
}
.btn-danger-transparent:hover {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

/* End::btn-danger-transparent */
/* Start::btn-success-transparent */
.btn-success-transparent {
  background-color: rgba(var(--success-rgb), 0.2);
  border-color: rgba(var(--success-rgb), 0.2);
  color: rgb(var(--success-rgb));
}
.btn-success-transparent:hover {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

/* End::btn-success-transparent */
.btn-outline-light {
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-close {
  background-image: none;
  float: var(--float-end);
  width: auto;
  height: auto;
}
.btn-close i {
  font-size: 20px;
  line-height: 0;
}

.carousel-indicators {
  position: inherit;
  margin-block-start: 1.5rem;
}

.carousel-indicators [data-bs-target] {
  background-color: #fff;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  opacity: 1;
  border: 3px solid var(--primary02);
  transition: all ease 0.5s;
}
.carousel-indicators [data-bs-target].active {
  border-color: var(--primary-color);
}
.carousel-indicators [data-bs-target]:not(.active):hover {
  border-color: var(--primary05);
}

.carousel-item .card {
  background-color: var(--primary-color);
  color: #fff;
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}
.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(5%);
  }
  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-5%);
  }
}
.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(5%);
}

.dropdown-menu {
  border-color: var(--border);
  background-color: var(--custom-white);
}

.dropdown-item {
  color: var(--default-text-color);
  display: block;
  width: 100%;
  padding: 0.5rem 0.8rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  font-size: 14px;
  border: 0;
}
.dropdown-item:hover {
  background-color: var(--primary005);
  color: var(--default-text-color);
}

@media screen and (max-device-width: 991px) and (orientation: landscape) {
  .categories-dropdowns .main-dropdown {
    height: 14rem;
    overflow: auto;
  }
}
.categories-dropdowns {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.categories-dropdowns li {
  cursor: pointer;
  position: relative;
}

.categories-dropdowns ul li:last-child .categories-dropdowns__item {
  border-block-end: 0;
}

.categories-dropdowns ul {
  transition: all 320ms ease;
  display: none;
  inset-inline-start: -0.5rem;
  position: absolute;
  inset-block-start: 4.5rem;
  background-color: var(--custom-white);
  min-width: 20rem;
  border-radius: 0.25rem;
  list-style: none;
  z-index: 9999;
  padding: 0;
  box-shadow: 1px 3px 8px var(--black-1);
  font-size: 0.95rem;
}
.categories-dropdowns ul::before {
  content: "";
  position: absolute;
  inset-block-start: -6px;
  inset-inline-start: 10%;
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-block-start-color: var(--border);
  border-inline-start-color: var(--border);
  transform: rotate(45deg);
  background-color: var(--custom-white);
  z-index: 901;
  display: block;
  transition-duration: 0.25s;
}

.categories-dropdowns ul ul {
  transition: all 320ms ease;
  inset-inline-start: 100%;
  position: absolute;
  inset-block-start: 1rem;
  margin-inline-start: 1px;
  padding: 10px 5px;
}
.categories-dropdowns ul ul li {
  padding-block: 5px;
  padding-inline-end: 15px;
  padding-inline-start: 30px;
  transition: all 320ms ease;
  font-size: 0.95rem;
}
.categories-dropdowns ul ul::before {
  display: none;
}

@media (max-width: 991px) {
  .category-dropdown .main-dropdown.active {
    display: block;
  }
  .main-dropdown .categories-dropdowns__item.is-expanded ~ .category-menu {
    display: block;
  }
}
.categories-dropdowns__item {
  transition: all 320ms ease;
  padding: 12px;
  border-block-end: 1px solid var(--border);
  color: var(--color-dark);
}
.categories-dropdowns__item span:first-child {
  padding: 6px 8px;
  border-radius: 50%;
  color: var(--primary-color);
}
.categories-dropdowns__item:hover {
  color: var(--primary-color);
}

.category-item {
  color: var(--color-dark);
}
.category-item::before {
  content: "";
  height: 6px;
  width: 6px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  position: absolute;
  inset-inline-start: 13px;
  inset-block-start: 12px;
  transition: all ease 0.3s;
}

@media (max-width: 991.98px) {
  .categories-dropdowns .category-dropdown {
    position: initial;
  }
  .categories-dropdowns ul {
    inset-inline-start: 5%;
    inset-inline-end: 5%;
    inset-block-start: 5rem;
    min-width: auto;
  }
  .categories-dropdowns ul::before {
    display: none;
  }
  .categories-dropdowns ul ul {
    inset-inline-start: 0;
    position: relative;
    inset-block-start: 0;
    border-block-end: 1px solid var(--border);
    padding: 5px 15px;
    border-radius: 0;
    box-shadow: none;
  }
}
@media (min-width: 992px) {
  .categories-dropdowns .main-dropdown li:hover > ul {
    display: block;
  }
}
.form-control,
.form-select {
  border: 1px solid var(--border);
  color: var(--default-text-color);
  font-size: 0.875rem;
  line-height: 2;
  border-radius: 0.3125rem;
  background-color: var(--custom-white);
}
.form-control:hover, .form-control:focus:hover, .form-control:focus,
.form-select:hover,
.form-select:focus:hover,
.form-select:focus {
  color: var(--default-text-color);
  box-shadow: none;
  border-color: var(--border);
  background-color: var(--custom-white);
}
.form-control::placeholder,
.form-select::placeholder {
  color: var(--default-text-color);
  opacity: 0.65;
}

.form-range[type=range]::-webkit-slider-thumb {
  background: var(--primary-color);
}
.form-range[type=range]::-moz-range-thumb {
  background: var(--primary-color);
}
.form-range[type=range]::-ms-thumb {
  background: var(--primary-color);
}

.form-check-input {
  border: 1px solid var(--border);
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input:focus {
  border-color: var(--primary02);
  box-shadow: none;
}

.form-switch-lg {
  padding-inline-start: 3em;
}
.form-switch-lg .form-check-input {
  width: 2.5em;
  height: 1.5em;
  margin-block-start: 0;
  margin-inline-start: -3em;
}

.custom-form-group {
  position: relative;
  display: flex;
  align-items: center;
}
.custom-form-group .form-control {
  padding-inline-end: 60px;
  padding-inline-start: 20px;
  line-height: 2.25;
}
.custom-form-group .form-control.form-control-lg {
  min-height: calc(1.5em + 1rem + 21px);
}
.custom-form-group .custom-form-btn {
  position: absolute;
  inset-inline-end: 0.4rem;
}

.form-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--muted);
}

.form-label {
  font-size: 0.9375rem;
  font-weight: 500;
  margin-block-end: 0.5rem;
}

.input-group-text {
  border-color: var(--border);
}

.card {
  margin-block-end: 1.5rem;
  border: 0px solid var(--border);
  border-radius: 0.625rem;
  box-shadow: var(--shadow);
  width: 100%;
  position: relative;
  background-color: var(--default-body-bg-color);
}
.card .filled {
  display: none;
}
.card .feature-avatar-1 {
  background-color: var(--gray-100);
  color: #fff;
}
.card .overlay-content {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.card .feature-content {
  background-color: var(--gray-100);
}
.card:hover .filled, .card.hover-active .filled {
  display: inline-block;
}
.card:hover .outline, .card.hover-active .outline {
  display: none;
}
.card:hover .feature-avatar, .card.hover-active .feature-avatar {
  transform: scale(1.28);
}
.card:hover .feature-avatar-1, .card.hover-active .feature-avatar-1 {
  background-color: var(--primary-color);
  color: #fff;
  transform: scale(0.8);
  transition: transform ease 0.3s;
}
.card:hover .feature-avatar-1.feature-avatar-1-secondary, .card.hover-active .feature-avatar-1.feature-avatar-1-secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.card:hover .feature-avatar-1.feature-avatar-1-success, .card.hover-active .feature-avatar-1.feature-avatar-1-success {
  background-color: rgba(var(--success-rgb), 0.1);
}
.card:hover .feature-avatar-1.feature-avatar-1-danger, .card.hover-active .feature-avatar-1.feature-avatar-1-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
}
.card:hover .feature-avatar-1.feature-avatar-1-pink, .card.hover-active .feature-avatar-1.feature-avatar-1-pink {
  background-color: rgba(var(--pink-rgb), 0.1);
}
.card:hover .feature-avatar-2, .card.hover-active .feature-avatar-2 {
  background-color: var(--primary-color);
  color: #fff;
}
.card:hover .feature-avatar-2.feature-avatar-2-primary, .card.hover-active .feature-avatar-2.feature-avatar-2-primary {
  background-color: var(--primary-color);
}
.card:hover .feature-avatar-2.feature-avatar-2-secondary, .card.hover-active .feature-avatar-2.feature-avatar-2-secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.card:hover .feature-avatar-2.feature-avatar-2-success, .card.hover-active .feature-avatar-2.feature-avatar-2-success {
  background-color: rgba(var(--success-rgb), 0.1);
}
.card:hover .feature-avatar-2.feature-avatar-2-danger, .card.hover-active .feature-avatar-2.feature-avatar-2-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
}
.card:hover .feature-avatar-2.feature-avatar-2-pink, .card.hover-active .feature-avatar-2.feature-avatar-2-pink {
  background-color: rgba(var(--pink-rgb), 0.1);
}
.card:hover .feature-avatar-3, .card.hover-active .feature-avatar-3 {
  background-color: var(--white-2);
}
.card:hover .overlay-content, .card.hover-active .overlay-content {
  opacity: 1;
}
.card:hover .muted-content, .card.hover-active .muted-content {
  color: #fff;
  opacity: 0.7;
}
.card .tool-img-white {
  display: none;
}

.card-body {
  padding: 1.5rem;
}

.pricing-card {
  transition: all ease 0.3s;
  box-shadow: none;
}
.pricing-card:hover {
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.pricing-card.pricing-card-premium:hover {
  box-shadow: 0px 4px 15px 0px var(--primary05);
}

.feature-card {
  transition: all ease 0.3s;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  cursor: pointer;
}

.feature-card-1 {
  background-color: var(--primary-color);
  color: #fff;
  z-index: 1;
}
.feature-card-1::before {
  content: "$";
  position: absolute;
  font-size: 150px;
  font-weight: 600;
  color: #fff;
  inset-inline-end: 5%;
  inset-block-start: 50%;
  transform: translateY(-50%);
  opacity: 0.1;
  z-index: -1;
}

.feature-card-2 {
  box-shadow: none;
  border: 1px solid var(--border);
}
.feature-card-2:not(.active):hover, .feature-card-2.active {
  box-shadow: var(--shadow);
  border-color: transparent;
}

.feature-card-3 {
  transition: all ease 0.3s;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
}
.feature-card-3::before, .feature-card-3::after {
  content: "";
  position: absolute;
  inset-inline-end: -8%;
  inset-block-end: 0%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #fff;
  visibility: hidden;
  opacity: 0.07;
  z-index: -1;
}
.feature-card-3::before {
  inset-inline-end: 0%;
  inset-block-end: -25%;
}
.feature-card-3:hover {
  background-color: var(--primary-color);
}
.feature-card-3:hover::before, .feature-card-3:hover::after {
  visibility: visible;
}
.feature-card-3.secondary:hover {
  background-color: rgb(var(--secondary-rgb));
}

.feature-card-4 .bg-deck {
  background-color: var(--gray-100);
}
.feature-card-4:hover .bg-deck {
  background-color: var(--gray-200);
}
.feature-card-4 .icon {
  background-color: var(--custom-white);
}
.feature-card-4.feature-card-4-primary .bg-deck {
  background-color: var(--primary01);
}
.feature-card-4.feature-card-4-primary .icon {
  border: 1px solid var(--primary-color);
}
.feature-card-4.feature-card-4-primary .icon .feature-avatar {
  background-color: var(--primary-color);
  color: #fff;
}
.feature-card-4.feature-card-4-primary.hover:hover .bg-deck {
  background-color: var(--primary-color);
}
.feature-card-4.feature-card-4-primary.hover:hover .avatar {
  transform: scale(1);
  background-color: #fff;
  color: var(--primary-color);
}
.feature-card-4.feature-card-4-primary.hover:hover .icon {
  background-color: var(--primary-color);
}
.feature-card-4.feature-card-4-primary.hover .bg-deck {
  background-color: var(--primary01);
}
.feature-card-4.feature-card-4-secondary .bg-deck {
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.feature-card-4.feature-card-4-secondary .icon {
  border: 1px solid rgb(var(--secondary-rgb));
}
.feature-card-4.feature-card-4-secondary .icon .feature-avatar {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}
.feature-card-4.feature-card-4-secondary.hover:hover .bg-deck {
  background-color: rgb(var(--secondary-rgb));
}
.feature-card-4.feature-card-4-secondary.hover:hover .avatar {
  transform: scale(1);
  background-color: #fff;
  color: rgb(var(--secondary-rgb));
}
.feature-card-4.feature-card-4-secondary.hover:hover .icon {
  background-color: rgb(var(--secondary-rgb));
}
.feature-card-4.feature-card-4-secondary.hover .bg-deck {
  background-color: rgba(var(--secondary-rgb), 0.1);
}
.feature-card-4.feature-card-4-success .bg-deck {
  background-color: rgba(var(--success-rgb), 0.1);
}
.feature-card-4.feature-card-4-success .icon {
  border: 1px solid rgb(var(--success-rgb));
}
.feature-card-4.feature-card-4-success .icon .feature-avatar {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}
.feature-card-4.feature-card-4-success.hover:hover .bg-deck {
  background-color: rgb(var(--success-rgb));
}
.feature-card-4.feature-card-4-success.hover:hover .avatar {
  transform: scale(1);
  background-color: #fff;
  color: rgb(var(--success-rgb));
}
.feature-card-4.feature-card-4-success.hover:hover .icon {
  background-color: rgb(var(--success-rgb));
}
.feature-card-4.feature-card-4-success.hover .bg-deck {
  background-color: rgba(var(--success-rgb), 0.1);
}
.feature-card-4.feature-card-4-danger .bg-deck {
  background-color: rgba(var(--danger-rgb), 0.1);
}
.feature-card-4.feature-card-4-danger .icon {
  border: 1px solid rgb(var(--danger-rgb));
}
.feature-card-4.feature-card-4-danger .icon .feature-avatar {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}
.feature-card-4.feature-card-4-danger.hover:hover .bg-deck {
  background-color: rgb(var(--danger-rgb));
}
.feature-card-4.feature-card-4-danger.hover:hover .avatar {
  transform: scale(1);
  background-color: #fff;
  color: rgb(var(--danger-rgb));
}
.feature-card-4.feature-card-4-danger.hover:hover .icon {
  background-color: rgb(var(--danger-rgb));
}
.feature-card-4.feature-card-4-danger.hover .bg-deck {
  background-color: rgba(var(--danger-rgb), 0.1);
}
.feature-card-4.feature-card-4-info .bg-deck {
  background-color: rgba(var(--info-rgb), 0.1);
}
.feature-card-4.feature-card-4-info .icon {
  border: 1px solid rgb(var(--info-rgb));
}
.feature-card-4.feature-card-4-info .icon .feature-avatar {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}
.feature-card-4.feature-card-4-info.hover:hover .bg-deck {
  background-color: rgb(var(--info-rgb));
}
.feature-card-4.feature-card-4-info.hover:hover .avatar {
  transform: scale(1);
  background-color: #fff;
  color: rgb(var(--info-rgb));
}
.feature-card-4.feature-card-4-info.hover:hover .icon {
  background-color: rgb(var(--info-rgb));
}
.feature-card-4.feature-card-4-info.hover .bg-deck {
  background-color: rgba(var(--info-rgb), 0.1);
}
.feature-card-4.feature-card-4-warning .bg-deck {
  background-color: rgba(var(--warning-rgb), 0.1);
}
.feature-card-4.feature-card-4-warning .icon {
  border: 1px solid rgb(var(--warning-rgb));
}
.feature-card-4.feature-card-4-warning .icon .feature-avatar {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}
.feature-card-4.feature-card-4-warning.hover:hover .bg-deck {
  background-color: rgb(var(--warning-rgb));
}
.feature-card-4.feature-card-4-warning.hover:hover .avatar {
  transform: scale(1);
  background-color: #fff;
  color: rgb(var(--warning-rgb));
}
.feature-card-4.feature-card-4-warning.hover:hover .icon {
  background-color: rgb(var(--warning-rgb));
}
.feature-card-4.feature-card-4-warning.hover .bg-deck {
  background-color: rgba(var(--warning-rgb), 0.1);
}

.feature-card-5 {
  background-color: var(--custom-white);
  margin: 20px;
  z-index: 1;
  border-radius: 0.625rem;
}
.feature-card-5::after {
  content: "";
  width: calc(100% + 30px);
  height: 90%;
  border-radius: 7px;
  position: absolute;
  inset-block-end: -15px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  background-color: var(--gray-200);
  transition: all ease-in-out 0.3s;
  z-index: -1;
}
.feature-card-5.feature-card-5-primary:hover::after {
  background-color: var(--primary02);
}
.feature-card-5.feature-card-5-primary::after {
  background-color: var(--primary005);
}
.feature-card-5.feature-card-5-secondary:hover::after {
  background-color: rgba(var(--secondary-rgb), 0.2);
}
.feature-card-5.feature-card-5-secondary::after {
  background-color: rgba(var(--secondary-rgb), 0.05);
}
.feature-card-5.feature-card-5-success:hover::after {
  background-color: rgba(var(--success-rgb), 0.2);
}
.feature-card-5.feature-card-5-success::after {
  background-color: rgba(var(--success-rgb), 0.05);
}

.feature-card-8 {
  background-color: #fff;
  margin: 20px;
  z-index: 1;
}
.feature-card-8::after {
  content: "";
  width: calc(100% + 30px);
  height: 90%;
  border-radius: 7px;
  position: absolute;
  inset-block-start: -15px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  background-color: var(--gray-200);
  transition: all ease-in-out 0.3s;
  z-index: -1;
}
.feature-card-8.feature-card-8-primary:hover::after {
  background-color: var(--primary02);
}
.feature-card-8.feature-card-8-secondary:hover::after {
  background-color: rgba(var(--secondary-rgb), 0.2);
}
.feature-card-8.feature-card-8-success:hover::after {
  background-color: rgba(var(--success-rgb), 0.2);
}

.feature-card-6:hover, .feature-card-6.hover-active {
  background-color: var(--primary-color);
  color: #fff;
}

.feature-card-7 {
  position: relative;
  background-color: var(--custom-white);
  transform-style: preserve-3d;
}
.feature-card-7::before, .feature-card-7::after {
  content: "";
  height: 100%;
  position: absolute;
  border-radius: calc(0.625rem + 10px);
  inset-inline-start: 50%;
  opacity: 0;
  transition: opacity ease 0.3s;
}
.feature-card-7::before {
  width: 96%;
  inset-block-end: -6%;
  background-color: var(--primary05);
  transform: translateX(-50%) translateZ(-1px);
}
.feature-card-7::after {
  width: 91%;
  inset-block-end: -12%;
  background-color: var(--primary02);
  transform: translateX(-50%) translateZ(-2px);
}
.feature-card-7:hover::before, .feature-card-7:hover::after, .feature-card-7.hover-active::before, .feature-card-7.hover-active::after {
  opacity: 1;
}
.feature-card-7.feature-card-7-secondary::before {
  background-color: rgba(var(--secondary-rgb), 0.5);
}
.feature-card-7.feature-card-7-secondary::after {
  background-color: rgba(var(--secondary-rgb), 0.2);
}
.feature-card-7.feature-card-7-success::before {
  background-color: rgba(var(--success-rgb), 0.5);
}
.feature-card-7.feature-card-7-success::after {
  background-color: rgba(var(--success-rgb), 0.2);
}
.feature-card-7.feature-card-7-danger::before {
  background-color: rgba(var(--danger-rgb), 0.5);
}
.feature-card-7.feature-card-7-danger::after {
  background-color: rgba(var(--danger-rgb), 0.2);
}

.feature-card-9 {
  transition: all 350ms ease-in;
  padding-block-end: 0;
  overflow: hidden;
  z-index: 1;
}
.feature-card-9:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.feature-card-9:hover svg {
  fill: #fff;
}
.feature-card-9:hover::before, .feature-card-9:hover::after {
  visibility: visible;
}
.feature-card-9:hover .subtitle {
  color: #fff;
}
.feature-card-9.hover {
  background-color: var(--primary-color);
  color: #fff;
}
.feature-card-9.hover svg {
  fill: #fff;
}
.feature-card-9.hover::before, .feature-card-9.hover::after {
  visibility: visible;
}
.feature-card-9.hover .subtitle {
  color: #fff;
}
.feature-card-9::before, .feature-card-9::after {
  content: "";
  position: absolute;
  inset-inline-end: -8%;
  inset-block-start: 0;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #fff;
  visibility: hidden;
  opacity: 0.07;
  z-index: -1;
}
.feature-card-9::before {
  inset-inline-end: 0;
  inset-block-start: -12%;
}

.feature-card-10 {
  background-color: var(--custom-white);
}
.feature-card-10 .icon {
  padding: 15px 18px;
  border-start-start-radius: 10%;
  border-start-end-radius: 30%;
  border-end-end-radius: 50%;
  border-end-start-radius: 70%;
  color: #fff;
}
.feature-card-10 .icon::before {
  content: "";
  position: absolute;
  inset-inline-start: 10px;
  inset-block-start: -3px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--custom-white);
  opacity: 0.2;
}

.feature-card-11 {
  border-start-start-radius: 50% 10%;
  border-start-end-radius: 20% 40%;
  border-end-end-radius: 50% 10%;
  border-end-start-radius: 20% 40%;
  background-color: transparent;
  margin-block-end: 0;
}
.feature-card-11 .icon {
  padding: 13px 16px;
  border-start-start-radius: 50% 10%;
  border-start-end-radius: 20% 40%;
  border-end-end-radius: 50% 10%;
  border-end-start-radius: 20% 40%;
  color: #fff;
  margin-inline-end: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 15px;
  transition: all ease 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
}
.feature-card-11 .icon::before {
  content: "";
  position: absolute;
  inset-inline-start: -10px;
  inset-block-start: -10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.08;
}
.feature-card-11:hover .icon {
  transform: rotate(45deg);
}

.feature-card-12 {
  transition-duration: 0.3s;
}
.feature-card-12:hover {
  box-shadow: 0px 6px 18px 4px rgba(6, 10, 48, 0.08);
  transform: translateY(10px);
}

.feature-card-13 {
  transition: all 350ms ease-in;
  z-index: 1;
  text-align: start;
  background: var(--custom-white) url(../images/png/74.png) 95% 95%/auto no-repeat scroll;
  background-size: 20% 32%;
}
.feature-card-13:hover {
  background: var(--primary-color) url(../images/png/73.png) 95% 95%/auto no-repeat scroll !important;
  background-size: 20% 32% !important;
  color: #fff;
  transform: scale(1.03);
}
.feature-card-13:hover .subtitle {
  color: #fff;
}
.feature-card-13.hover {
  background: var(--primary-color) url(../images/png/73.png) 95% 95%/auto no-repeat scroll;
  background-size: 20% 32%;
  color: #fff;
  transform: scale(1.03);
}
.feature-card-13.hover .subtitle {
  color: #fff;
}
.feature-card-13::before {
  content: "";
  position: absolute;
  inset-inline-end: -30px;
  inset-block-start: -11%;
  height: 40%;
  filter: contrast(0.95);
  width: 28%;
  background-color: var(--custom-white);
  z-index: -1;
  border-radius: 50%;
  opacity: 0.06;
}

.feature-card-14 {
  transition: all 350ms ease-in;
  z-index: 1;
  text-align: start;
  background: linear-gradient(to right, var(--primary-color) 0%, var(--primary08) 100%) !important;
}
.feature-card-14::before {
  content: "";
  position: absolute;
  background-image: url(../images/backgrounds/16.jpg);
  inset-block-start: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  background-size: cover;
  z-index: -1;
  opacity: 0.4;
}

.feature-card-15 {
  transition-duration: 0.3s;
}
.feature-card-15:hover {
  box-shadow: 0px 6px 18px 4px rgba(6, 10, 48, 0.08);
  transform: translateY(10px);
}
.feature-card-15 .feature-bg {
  position: relative;
}
.feature-card-15 .feature-bg::before {
  content: "";
  position: absolute;
  inset-inline-start: -40%;
  inset-block-start: 22%;
  height: 47px;
  width: 47px;
  border-radius: 10%;
  z-index: 0;
}
.feature-card-15.primary .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, var(--primary02) 90%);
}
.feature-card-15.blue .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--blue-rgb), 0.2) 90%);
}
.feature-card-15.pink .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--pink-rgb), 0.2) 90%);
}
.feature-card-15.success .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--success-rgb), 0.2) 90%);
}
.feature-card-15.purple .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--purple-rgb), 0.2) 90%);
}
.feature-card-15.danger .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--danger-rgb), 0.2) 90%);
}
.feature-card-15.secondary .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--secondary-rgb), 0.2) 90%);
}
.feature-card-15.teal .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--teal-rgb), 0.2) 90%);
}
.feature-card-15.coffee .feature-bg {
  background: linear-gradient(to right, #936237 0%, #603e23 100%);
}
.feature-card-15.coffee .feature-icon {
  background: rgba(96, 62, 35, 0.2);
  border: 1px solid #936237;
}
.feature-card-15.coffee .feature-icon .avatar {
  background: linear-gradient(to right, #936237 0%, #603e23 100%);
}
.feature-card-15.warning .feature-bg::before {
  background: linear-gradient(218deg, transparent 10%, rgba(var(--warning-rgb), 0.2) 90%);
}

.feature-card-16 {
  transition: all 350ms ease-in;
  padding-block-end: 0;
  overflow: hidden;
  z-index: 1;
}
.feature-card-16::before, .feature-card-16::after {
  content: "";
  position: absolute;
  inset-inline-end: -8%;
  inset-block-start: 0;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: var(--primary-color);
  opacity: 0.05;
  z-index: -1;
}
.feature-card-16::before {
  inset-inline-end: 0;
  inset-block-start: -12%;
}
.feature-card-16.secondary::before, .feature-card-16.secondary::after {
  background-color: rgb(var(--secondary-rgb));
}
.feature-card-16.success::before, .feature-card-16.success::after {
  background-color: rgb(var(--success-rgb));
}
.feature-card-16.danger::before, .feature-card-16.danger::after {
  background-color: rgb(var(--danger-rgb));
}
.feature-card-16.warning::before, .feature-card-16.warning::after {
  background-color: rgb(var(--warning-rgb));
}
.feature-card-16.pink::before, .feature-card-16.pink::after {
  background-color: rgb(var(--pink-rgb));
}
.feature-card-16.info::before, .feature-card-16.info::after {
  background-color: rgb(var(--info-rgb));
}

.feature-card-17 {
  position: relative;
  margin-block-end: 3rem !important;
}
.feature-card-17 .icon {
  position: absolute;
  inset-block-start: -14%;
  inset-inline-start: 45%;
}
.feature-card-17.primary {
  border-block-start: 2px solid var(--primary-color);
}
.feature-card-17.secondary {
  border-block-start: 2px solid rgb(var(--secondary-rgb));
}
.feature-card-17.success {
  border-block-start: 2px solid rgb(var(--success-rgb));
}
.feature-card-17.danger {
  border-block-start: 2px solid rgb(var(--danger-rgb));
}
.feature-card-17.info {
  border-block-start: 2px solid rgb(var(--info-rgb));
}
.feature-card-17.pink {
  border-block-start: 2px solid rgb(var(--pink-rgb));
}

.card-aside-column {
  min-width: 5rem;
  width: 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  background: no-repeat center/cover;
}

.card-header {
  background-color: #fff;
  border-color: var(--border);
}

.accordion-item {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border-color: var(--border);
}

.accordion-button {
  color: var(--default-text-color);
  background-color: var(--custom-white);
}
.accordion-button:hover {
  color: var(--primary-color);
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: var(--primary-color);
  background-color: var(--primary01);
  box-shadow: none;
}

.accordion-style-1 .accordion-button::after {
  content: "\f4fa";
  font-family: bootstrap-icons !important;
  background-image: none;
  color: var(--black-3);
}
.accordion-style-1 .accordion-button:not(.collapsed)::after {
  content: "\f2e6";
  font-family: bootstrap-icons !important;
  transform: none;
  background-image: none;
  color: var(--primary-color);
}
.accordion-style-1 .accordion-button.collapsed {
  border-radius: calc(0.375rem - 1px);
}
.accordion-style-1 .accordion-item {
  margin-block-end: 10px;
  border-radius: calc(0.375rem - 1px);
}
.accordion-style-1 .accordion-item:not(:first-of-type) {
  border-block-start: 1px solid var(--border);
}

.accordion-collapse.collapse.show ~ .communicate-icons {
  display: none;
}

.google-accordion .accordion-button {
  justify-content: space-between;
}
.google-accordion .accordion-button::after {
  display: none;
}
.google-accordion .accordion-button[aria-expanded=true] .arrow-icon {
  transform: rotate(180deg);
}
.google-accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion-style-1 .accordion-item:last-child {
  margin-bottom: 0;
}

.modal-header {
  padding: 1.25rem;
}

.modal-content {
  border: none;
}

.modal-body {
  padding: 1.25rem;
}

.offer-modal .modal-content {
  background-color: var(--primary-color) !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px -1px 20px 2px;
}
.offer-modal .modal-content::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/backgrounds/15.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  border-radius: 0.5rem;
}

.tooltip-inner {
  padding: 0.5rem 0.75rem;
}

.nav-link {
  color: var(--color-dark);
}
.nav-link:hover, .nav-link.active {
  color: var(--primary-color);
}

.tab-style-1 .nav-link {
  padding: 0.75rem 2.5rem;
  font-weight: 600;
}

.tab-style-2 .tab-style-2-link::after {
  z-index: 2;
}
.tab-style-2 .tab-style-2-link.active + .feature-card-3, .tab-style-2 .tab-style-2-link:hover + .feature-card-3 {
  background-color: var(--primary-color);
  color: #fff !important;
  border: 1px solid transparent;
}
.tab-style-2 .tab-style-2-link.active + .feature-card-3 .tab-heading, .tab-style-2 .tab-style-2-link:hover + .feature-card-3 .tab-heading {
  color: #fff !important;
}
.tab-style-2 .tab-style-2-link.active + .feature-card-3::before, .tab-style-2 .tab-style-2-link.active + .feature-card-3::after, .tab-style-2 .tab-style-2-link:hover + .feature-card-3::before, .tab-style-2 .tab-style-2-link:hover + .feature-card-3::after {
  visibility: visible;
}
.tab-style-2 .tab-style-2-link.active + .feature-card-3 p, .tab-style-2 .tab-style-2-link:hover + .feature-card-3 p {
  color: #fff;
}
.tab-style-2 .tab-style-2-link:not(.active) + .feature-card-3 {
  border: 1px solid var(--border);
}
.tab-style-2 .tab-style-2-link:not(.active):hover + .feature-card-3 {
  color: var(--primary-color);
}

.tab-style-3 .tab-style-3-link::after {
  z-index: 2;
}
.tab-style-3 .tab-style-3-link.active + .feature-card-3, .tab-style-3 .tab-style-3-link:hover + .feature-card-3 {
  background-color: var(--primary09);
  color: #fff !important;
  border: 1px solid var(--primary-color);
  border-radius: 0.3rem;
}
.tab-style-3 .tab-style-3-link.active + .feature-card-3::before, .tab-style-3 .tab-style-3-link.active + .feature-card-3::after, .tab-style-3 .tab-style-3-link:hover + .feature-card-3::before, .tab-style-3 .tab-style-3-link:hover + .feature-card-3::after {
  visibility: visible;
}
.tab-style-3 .tab-style-3-link.active + .feature-card-3 .icon, .tab-style-3 .tab-style-3-link:hover + .feature-card-3 .icon {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
  color: #fff;
}
.tab-style-3 .tab-style-3-link:not(.active) + .feature-card-3 {
  border: 1px solid var(--border);
  border-radius: 0.3rem;
}
.tab-style-3 .tab-style-3-link:not(.active):hover + .feature-card-3 {
  color: #fff;
  border: 1px solid var(--primary-color);
}
.tab-style-3 .icon {
  background-color: var(--primary01);
  color: var(--primary-color);
  border-radius: 50px;
  height: 2.3rem;
  width: 2.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 5px;
  line-height: 0;
}

.tab-style-4 .tab-style-4-link::after {
  z-index: 2;
}
.tab-style-4 .tab-style-4-link.active + .feature-card-3 .tab-heading, .tab-style-4 .tab-style-4-link:hover + .feature-card-3 .tab-heading {
  color: #fff !important;
}
.tab-style-4 .tab-style-4-link.active + .feature-card-3::before, .tab-style-4 .tab-style-4-link.active + .feature-card-3::after, .tab-style-4 .tab-style-4-link:hover + .feature-card-3::before, .tab-style-4 .tab-style-4-link:hover + .feature-card-3::after {
  visibility: visible;
}
.tab-style-4 .tab-style-4-link.active + .feature-card-3 .tab-border, .tab-style-4 .tab-style-4-link:hover + .feature-card-3 .tab-border {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 2px;
  background: var(--primary-color);
  border-radius: 1px;
  inset-block-start: 8px;
}
.tab-style-4 .tab-style-4-link.active + .feature-card-3 .card-body, .tab-style-4 .tab-style-4-link:hover + .feature-card-3 .card-body {
  color: var(--primary-color);
}
.tab-style-4 .tab-style-4-link:not(.active):hover + .feature-card-3 {
  color: var(--primary-color);
}
.tab-style-4 .tab-style-4-link:not(.active):hover .card-body {
  color: var(--primary-color);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary-color);
  color: #fff;
}

.page-link {
  color: var(--default-text-color);
  border-color: var(--border);
  background-color: var(--custom-white);
}
.page-link:hover {
  border-color: var(--border);
  background-color: var(--primary01);
  color: var(--primary-color);
}
.page-link:focus {
  box-shadow: none;
  background-color: var(--primary01);
  color: var(--primary-color);
}

.page-item.page-prev.disabled {
  color: var(--default-text-color);
  opacity: 0.8;
}
.page-item.page-prev.disabled .page-link {
  background-color: var(--custom-white);
  border-color: var(--border);
  opacity: 0.7;
}
.page-item.active .page-link {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  box-shadow: none;
}
.page-item.active .page-link:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.table {
  color: var(--default-text-color);
  border-color: var(--border);
  vertical-align: middle;
}

.table > :not(caption) > * > * {
  padding: 1.2rem 1.5rem;
}

th {
  font-weight: 500;
}

.pricing-table {
  font-weight: 500;
}
.pricing-table th,
.pricing-table td:not(.pricing-feature) {
  text-align: center;
}
.pricing-table .pricing-feature {
  color: var(--color-dark);
  font-weight: 600;
}
.pricing-table .pricing-feature.pricing-category {
  background-color: var(--gray-100);
}
.pricing-table .main-feature {
  background-color: var(--gray-100);
}
.pricing-table th {
  vertical-align: baseline;
}
.pricing-table td {
  vertical-align: middle;
}

.border-end-primary {
  border-inline-end: 1px solid var(--primary-color);
}

.table-bg-primary-light {
  background-color: var(--primary01) !important;
  border-color: var(--primary-color) !important;
}

.table tbody tr:last-child .table-bg-primary-light {
  border-block-end: 1px solid var(--primary-color) !important;
}

.pricing-feature {
  border-inline-end-width: 0 !important;
}

.table-bg-primary {
  background-color: var(--primary01) !important;
}

.table-hover > tbody > tr:hover > * {
  background: var(--primary005);
  color: var(--default-text-color);
  --bs-table-accent-bg: transparent;
}

.border-hidden {
  border-style: hidden;
}

.spec-tabs .nav-link {
  text-align: start;
  padding: 10px 12px;
  font-size: 15px;
  align-items: center;
  display: flex;
  border: 1px solid var(--border);
  margin-block-end: 1rem;
  font-weight: 600;
  color: var(--default-text-color);
}
.spec-tabs .nav-link span {
  background-color: var(--primary01);
  padding: 7px 12px;
  font-size: 20px;
  border-radius: 50px;
  margin-inline-end: 10px;
  color: var(--primary-color);
}
.spec-tabs .nav-link.active span {
  background-color: var(--white-2);
  color: #fff;
}
.spec-tabs .nav-link:hover {
  box-shadow: var(--shadow);
}
.spec-tabs .nav-link:hover span {
  background-color: var(--primary-color);
  color: #fff;
}
.spec-tabs .nav-link:last-child {
  margin-block-end: 0;
}
.spec-tabs .tab-content {
  padding: 50px;
  border: 1px solid var(--border);
  border-radius: 5px;
}
@media (min-width: 992px) {
  .spec-tabs .nav-pills.nav {
    margin-inline-end: 4rem;
  }
}

/* ######## CUSTOM ######## */
.accept-cookies {
  position: fixed;
  z-index: 9999;
  inset-inline-start: 0;
  inset-block-end: 30px;
  max-width: 23rem;
  background-color: var(--custom-white);
  padding: 20px;
  border-radius: 3px;
  border: 1px solid var(--border);
  box-shadow: 0 8px 20px 0 rgba(40, 37, 89, 0.6);
  margin-inline-start: 30px;
  text-align: start;
  display: block;
  display: none;
}

/* Start::body */
body {
  font-size: 1rem;
  font-family: "Hind Siliguri", sans-serif;
  font-weight: 400;
  color: var(--default-text-color);
  background-color: var(--default-body-bg-color);
  line-height: 1.5;
  text-align: start;
  overflow-x: clip;
  padding: 0;
  margin: 0;
}

.main-body {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main-body.modal-open {
  padding-inline-end: 0 !important;
  overflow: hidden !important;
}

/* End::body */
/* Start::basic */
.row-deck > .col,
.row-deck > [class*=col-] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.row-deck > .col .card,
.row-deck > [class*=col-] .card {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  transition: all ease 0.3s;
}

::-webkit-scrollbar-thumb {
  background: var(--primary02);
}

*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

section {
  position: relative;
}

.section {
  padding: 70px 0px;
}

.section-2 {
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary08) 100%);
  z-index: 1;
}
.section-2::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/svg/1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.065;
  z-index: -1;
}

.bg-client {
  padding: 50px 0;
}
.bg-client::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 6%;
  height: 100%;
  width: 100%;
  background-image: url("../images/backgrounds/16.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.065;
  border-radius: 5px;
}

/* End::basic */
/* Start::footer */
.footer {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.footer .footer-link {
  font-size: 14px;
  color: #fff;
}
.footer .footer-link:hover {
  color: #fff;
}

@media (max-width: 575px) {
  .footer-link {
    border-inline-end: 0 !important;
  }
}
.footer-icon .list-item::before {
  content: "\f231";
  font-family: "bootstrap-icons";
  margin-inline-end: 6px;
  font-size: 8px;
  color: #fff;
  padding: 4px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  line-height: 1;
  display: inline-block;
}

.footer-btn-list .footer-btn {
  border: 0;
  color: rgba(255, 255, 255, 0.9);
  height: 37px;
  width: 37px;
  padding: 0px;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.footer-btn-list .footer-btn:hover, .footer-btn-list .footer-btn:focus:hover {
  color: #fff;
  border-color: var(--primary-color);
}
.footer-btn-list .footer-btn:focus {
  border-color: var(--primary-color);
}
.footer-btn-list .footer-btn:active {
  border-color: var(--primary-color);
}

/* End::footer */
.back-to-top {
  position: fixed;
  height: 50px;
  width: 50px;
  background: rgb(var(--secondary-rgb));
  border: 2px solid rgb(var(--secondary-rgb));
  color: #fff;
  inset-inline-end: 2.3rem;
  inset-block-end: 8rem;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.back-to-top:hover {
  color: rgb(var(--secondary-rgb));
  background-color: var(--custom-white);
}

.banner-1 {
  height: 100%;
  width: 100%;
  background-image: url("../images/backgrounds/2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  z-index: 1;
  position: relative;
}
.banner-1::before {
  content: "";
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary08) 100%);
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  opacity: 0.9;
}
.banner-1:hover .reseller-img {
  transform: translateY(-10px);
}

.banner-arrow-down::after {
  content: "";
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%) rotate(45deg);
  height: 20px;
  width: 20px;
  background: var(--primary-color);
  inset-block-end: -10px;
  border-start-start-radius: 100%;
}

.banner-2 {
  color: #fff;
  background-image: url(../images/backgrounds/2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  position: relative;
}
.banner-2:hover .banner-bg::before {
  transform: translateY(-10px);
}
.banner-2:hover .price-main {
  transform: translateX(-20px);
}
.banner-2:hover .reseller-img {
  transform: translateY(-10px);
}
.banner-2::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary08) 100%);
  opacity: 0.9;
  z-index: -1;
}

.banner-3 {
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary08) 100%);
  color: #fff;
  padding: 30px 0px;
  z-index: 1;
  position: relative;
}
.banner-3::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/backgrounds/18.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}
.banner-3:hover .banner-bg::before,
.banner-3:hover .banner-bg {
  transform: translateY(-10px);
}
.banner-3:hover .reseller-img {
  transform: translateY(-10px);
}

.banner-4 {
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary08) 100%);
  color: #fff;
  z-index: 1;
  position: relative;
}
.banner-4::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/backgrounds/17.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}
.banner-4:hover .banner-bg::before,
.banner-4:hover .banner-bg {
  transform: translateY(-10px);
}
.banner-4:hover .reseller-img {
  transform: translateY(-10px);
}

.banner-5 {
  color: #fff;
  z-index: 1;
  position: relative;
  background-image: url("../images/backgrounds/14.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.banner-5::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary08) 100%);
  opacity: 0.8;
  z-index: -1;
}
.banner-5:hover .banner-bg::before,
.banner-5:hover .banner-bg {
  transform: translateY(-10px);
}
.banner-5:hover .reseller-img {
  transform: translateY(-10px);
}

.custom-bg {
  z-index: 1;
  position: relative;
}
.custom-bg::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/backgrounds/1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.13;
  z-index: -1;
}
.custom-bg.opacity-1::before {
  opacity: 0.1;
}
.custom-bg.zindex-0::before {
  z-index: 0;
}
.custom-bg:hover .banner-bg::before,
.custom-bg:hover .banner-bg {
  transform: translateY(-10px);
}
.custom-bg:hover .reseller-img {
  transform: translateY(-10px);
}

.construction-bg {
  position: relative;
  z-index: 1;
  background: radial-gradient(var(--primary09), #268fe6);
}
.construction-bg::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/patterns/28.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.08;
  z-index: -1;
  filter: invert(1);
}
.construction-bg .construction-heading {
  font-size: 3rem;
}
.construction-bg .counter-item {
  background-color: var(--white-1);
}
.construction-bg .counter-item:before {
  background-image: none;
}

.bg-pattern-2 {
  z-index: 1;
  position: relative;
}
.bg-pattern-2::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/backgrounds/9.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
}
.bg-pattern-2.bg-image1 {
  background: var(--primary-color);
}
.bg-pattern-2.bg-image1::before {
  background-image: url("../images/backgrounds/4.jpg");
  opacity: 0.2;
}
.bg-pattern-2.bg-image2 {
  background: var(--primary-color);
}
.bg-pattern-2.bg-image2::before {
  background-image: url("../images/backgrounds/8.jpg");
  opacity: 0.06;
}
.bg-pattern-2:hover .banner-bg::before,
.bg-pattern-2:hover .banner-bg {
  transform: translateY(-10px);
}
.bg-pattern-2:hover .reseller-img {
  transform: translateY(-10px);
}

.banner-bg {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: transform ease 0.8s;
}
.banner-bg::before {
  content: "";
  height: 100%;
  width: 400px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  fill: #fff;
  opacity: 0.1;
  z-index: -1;
  max-width: 100%;
}
.banner-bg.banner-img {
  min-height: 330px;
}
.banner-bg.banner-img::before {
  background-image: url("../images/svg/2.svg");
}
.banner-bg.banner-img1 {
  min-height: 330px;
}
.banner-bg.banner-img1::before {
  background-image: url("../images/svg/3.svg");
}
.banner-bg.banner-img2 {
  min-height: 340px;
  height: auto;
}
.banner-bg.banner-img2::before {
  background-image: url("../images/svg/4.svg");
}
.banner-bg::before,
.banner-bg .price-main,
.banner-bg .reseller-img {
  transition: transform ease 0.8s;
}
.banner-bg.acronis-image::before {
  content: "";
  height: 300px;
  width: 300px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #fff;
  opacity: 0.1;
  z-index: -1;
  border-radius: 50%;
  inset-inline-end: 20%;
  inset-block-start: -2%;
}
.banner-bg.about-image {
  filter: drop-shadow(17px 26px 2px rgba(0, 0, 0, 0.08));
}
.banner-bg.google-img-bg .reseller-img {
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}

@media (max-width: 991px) {
  .banner-bg.acronis-image::before {
    content: none;
  }
}
.bg-section {
  padding: 30px 0px;
  z-index: 1;
}
.bg-section::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/png/75.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.13;
  z-index: -1;
}

.bg-section-1 {
  z-index: 1;
}
.bg-section-1::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/png/72.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}

.bg-section-2 {
  padding: 30px 0px;
  background-color: var(--primary02);
}
.bg-section-2::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/png/78.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  opacity: 0.1;
}

.feature-blob {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.feature-blob::before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--primary-color);
  opacity: 0.1;
  z-index: -1;
  border-radius: 50%;
  inset-block-start: -2%;
}
.feature-blob::before {
  inset-inline-start: 2%;
  height: 400px;
  width: 400px;
}

.pricing-card.pricing-card-premium {
  overflow: hidden;
  position: relative;
}
.pricing-card.pricing-card-premium::before {
  content: "";
  position: absolute;
  inset-inline-end: -38px;
  inset-block-start: -24px;
  background-image: url("../images/svg/5.svg");
  background-repeat: no-repeat;
  background-position: top right;
  height: 150px;
  width: 150px;
  transform: rotate(31deg);
  transition: transform ease 0.3s;
}
.pricing-card.pricing-card-premium:hover::before {
  transform: rotate(41deg);
}

.build-img {
  transform: scale(1.25);
}

.blob-bg-sec {
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.blob-bg-sec::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset-block-start: 0;
  background-image: url("../images/backgrounds/7.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.08;
  transition: transform ease 0.3s;
  z-index: -1;
}

.faq-category-card .faq-category-icon {
  fill: var(--primary-color);
}
.faq-category-card:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.faq-category-card:hover .faq-category-icon {
  fill: #fff;
}

.step {
  position: relative;
}
.step:not(:last-child)::after {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(100% + 10px);
  inset-block-start: 50%;
  transform: translateY(-50%);
  inset-inline-start: calc(100% + 0.5px);
  background-color: var(--border);
}
.step.check, .step.active {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.steps-card {
  background-color: transparent;
  overflow: hidden;
  z-index: 1;
}
.steps-card::after {
  content: "";
  position: absolute;
  inset-inline-end: 0%;
  inset-block-start: 50%;
  transform: translateY(-50%);
  color: var(--gray-200);
  font-size: 130px;
  font-weight: bold;
  z-index: -1;
}
.steps-card.steps-content-1::after {
  content: "1";
}
.steps-card.steps-content-2::after {
  content: "2";
}
.steps-card.steps-content-3::after {
  content: "3";
}

.domain-names {
  background-color: var(--custom-white);
  border-radius: 10px;
  padding: 15px;
  position: relative;
  cursor: pointer;
  margin-block-end: 0.8rem;
  z-index: 1;
}

.badge-offer {
  position: absolute;
  inset-block-start: -4%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  margin: 0 auto;
  width: 45%;
}

.domain-names:hover {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
}
.domain-names:hover span,
.domain-names:hover p {
  color: #fff !important;
}

.review-quote {
  opacity: 0.3;
  font-size: 60px;
  line-height: 0;
}

.window-server-steps {
  position: absolute;
  width: 130px;
  height: 130px;
  background: var(--default-body-bg-color);
  color: var(--primary-color);
  text-align: end;
  border-radius: 8px;
  line-height: 123px;
  inset-block-start: -36px;
  -ms-transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
  padding-inline-end: 11px;
  inset-inline-end: -35px;
  pointer-events: none;
  transition: transform ease 0.8s;
}
.window-server-steps span {
  display: block;
  position: absolute;
  inset-block-end: 37%;
  z-index: 2;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  inset-inline-start: 30%;
  line-height: 0;
  font-size: 35px;
  opacity: 0.3;
}

.windows-server-animate:hover .window-server-steps {
  transform: skew(10deg);
}
.windows-server-animate:hover .window-server-steps span {
  transform: skew(-10deg);
}
.windows-server-animate:hover .avatar {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.linux-feature-list .border-primary:hover {
  box-shadow: 0 0.5rem 1rem var(--primary02);
}
.linux-feature-list .border-secondary:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--secondary-rgb), 0.2);
}
.linux-feature-list .border-success:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--success-rgb), 0.2);
}
.linux-feature-list .border-info:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--info-rgb), 0.2);
}
.linux-feature-list .border-pink:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--pink-rgb), 0.2);
}
.linux-feature-list .border-danger:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--danger-rgb), 0.2);
}
.linux-feature-list .border-purple:hover {
  box-shadow: 0 0.5rem 1rem rgba(var(--purple-rgb), 0.2);
}

.wr-price-server {
  background-color: var(--primary-color);
  padding: 9px 14px;
  font-size: 30px;
  border-radius: 50px;
  margin-inline-end: 10px;
  color: var(--gray-200);
}

.ribbone {
  width: 160px;
  height: 160px;
  overflow: hidden;
  position: absolute;
  z-index: 6;
  inset-block-start: 0;
  inset-inline-start: 0;
}
.ribbone span {
  inset-inline-end: 0px;
  inset-block-start: 17px;
  transform: rotate(-45deg);
  position: absolute;
  display: block;
  width: 260px;
  padding: 2px 0;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  font-size: 11px;
}
.ribbone.ribbone-1 span {
  inset-inline-end: 10px;
  inset-block-start: 10px;
  padding: 1px 0;
  font-size: 10px;
}
.ribbone.ribbone-2 {
  width: 180px;
  height: 180px;
}
.ribbone.ribbone-2 span {
  padding: 7px 0;
  font-size: 13px;
  inset-inline-end: 0;
  inset-block-start: 2rem;
}

.card:hover .feature-avatar-icon-1 {
  animation: pulse-animation 1s infinite alternate;
}
.card:hover .feature-avatar-icon-1 .avatar {
  transform: rotate(360deg);
}
.card:hover .feature-avatar-icon-1.secondary {
  animation: pulse-animation-secondary 1s infinite alternate;
}
.card:hover .feature-avatar-icon-1.info {
  animation: pulse-animation-info 1s infinite alternate;
}
.card:hover .feature-avatar-icon-1.success {
  animation: pulse-animation-success 1s infinite alternate;
}
.card:hover .feature-avatar-icon-2 .avatar {
  transform: translate(0px, -20px);
}
.card:hover .blob-bg {
  animation: transform 3s ease-in-out infinite both alternate, movement 2s ease-in-out infinite both;
}

.feature-avatar-icon-1 .avatar i {
  color: #fff;
}

.feature-avatar-icon-2 {
  border-radius: 50% 50% 0 0;
}
.feature-avatar-icon-2 .avatar {
  border-start-start-radius: 50%;
  border-start-end-radius: 0px;
  border-end-end-radius: 50%;
  border-end-start-radius: 0px i;
  border-end-start-radius-color: #fff;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px var(--primary02);
  }
  100% {
    box-shadow: 0 0 0 15px var(--primary02);
  }
}
@keyframes pulse-animation-secondary {
  0% {
    box-shadow: 0 0 0 0px rgba(var(--secondary-rgb), 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(var(--secondary-rgb), 0.2);
  }
}
@keyframes pulse-animation-info {
  0% {
    box-shadow: 0 0 0 0px rgba(var(--info-rgb), 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(var(--info-rgb), 0.2);
  }
}
@keyframes pulse-animation-success {
  0% {
    box-shadow: 0 0 0 0px rgba(var(--success-rgb), 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(var(--success-rgb), 0.2);
  }
}
.animate-svg-wave {
  background-color: #2489e6;
}

path {
  animation: pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.animated-button {
  border-color: var(--primary-color);
  border-radius: 0;
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 150ms ease;
}
.animated-button::after {
  content: "";
  position: absolute;
  display: block;
  inset-block-start: 0;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: var(--primary-color);
  z-index: -1;
  transition: all 150ms ease-in-out;
}
.animated-button:hover::after {
  width: 110%;
}

.blob-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, var(--primary-color) 100%);
  background-color: var(--primary-color);
  box-shadow: inset 10px 0 40px var(--primary08), inset -10px 0 20px var(--primary02), inset -40px 10px 100px rgba(255, 255, 255, 0.6);
  margin-block-end: 20px !important;
  margin: 0 auto;
  border-start-start-radius: 70% 60%;
  border-start-end-radius: 30% 40%;
  border-end-end-radius: 30% 60%;
  border-end-start-radius: 70% 40%;
}
.blob-bg:hover {
  animation: transform 3s ease-in-out infinite both alternate, movement 2s ease-in-out infinite both;
}
.blob-bg.secondary {
  border: 1px solid rgb(var(--secondary-rgb));
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, rgb(var(--secondary-rgb)) 100%);
  background-color: rgb(var(--secondary-rgb));
  box-shadow: inset 10px 0 40px rgba(var(--secondary-rgb), 0.8), inset -10px 0 20px rgba(var(--secondary-rgb), 0.2), inset -40px 10px 100px rgba(255, 255, 255, 0.6);
}
.blob-bg.success {
  border: 1px solid rgb(var(--success-rgb));
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, rgb(var(--success-rgb)) 100%);
  background-color: rgb(var(--success-rgb));
  box-shadow: inset 10px 0 40px rgba(var(--success-rgb), 0.8), inset -10px 0 20px rgba(var(--success-rgb), 0.2), inset -40px 10px 100px rgba(255, 255, 255, 0.6);
}
.blob-bg.pink {
  border: 1px solid rgb(var(--pink-rgb));
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, rgb(var(--pink-rgb)) 100%);
  background-color: rgb(var(--pink-rgb));
  box-shadow: inset 10px 0 40px rgba(var(--pink-rgb), 0.8), inset -10px 0 20px rgba(var(--pink-rgb), 0.2), inset -40px 10px 100px rgba(255, 255, 255, 0.6);
}
.blob-bg.teal {
  border: 1px solid rgb(var(--teal-rgb));
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, rgb(var(--teal-rgb)) 100%);
  background-color: rgb(var(--teal-rgb));
  box-shadow: inset 10px 0 40px rgba(var(--teal-rgb), 0.8), inset -10px 0 20px rgba(var(--teal-rgb), 0.2), inset -40px 10px 100px rgba(255, 255, 255, 0.6);
}
.blob-bg.danger {
  border: 1px solid rgb(var(--danger-rgb));
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, rgb(var(--danger-rgb)) 100%);
  background-color: rgb(var(--danger-rgb));
  box-shadow: inset 10px 0 40px rgba(var(--danger-rgb), 0.8), inset -10px 0 20px rgba(var(--danger-rgb), 0.2), inset -40px 10px 100px rgba(255, 255, 255, 0.6);
}

@keyframes transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes movement {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: translateY(20%) rotateY(10deg);
  }
}
.social-icons {
  padding: 8px;
  border-radius: 50%;
  line-height: 3.3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icons i {
  font-size: 16px;
  border-radius: 50%;
}

.blog-badge {
  position: absolute;
  inset-inline-start: 20px;
  inset-block-end: -10px;
  padding: 12px !important;
  font-size: 13px !important;
}

.blog-date {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  display: grid;
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
  color: #fff;
  padding: 10px;
  border-start-start-radius: 0px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 5px;
  text-align: center;
}

.cover-image {
  background-size: cover !important;
}

.product-label {
  padding: 0.35em 0.55em;
  font-size: 73%;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  background: var(--gray-200);
  float: var(--float-end);
  border: 1px solid var(--border);
  color: var(--default-text-color);
}

.tag {
  font-size: 0.75rem;
  color: var(--default-text-color);
  background-color: var(--gray-200);
  border-radius: 5px;
  padding: 0 0.5rem;
  line-height: 2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid var(--border);
}
.tag:hover {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s color, 0.3s background;
}

.tags > .tag {
  margin-block-end: 0.5rem;
}

.tags > .tag:not(:last-child) {
  margin-inline-end: 0.5rem;
}

.buy-now {
  position: fixed;
  inset-inline-end: 55px;
  inset-block-end: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
  color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 99;
  cursor: pointer;
}
.buy-now:hover {
  color: #fff;
}

.patterns-1 {
  inset-inline-start: -10px;
  inset-block-end: 0px;
  z-index: -1;
  position: absolute;
  opacity: 0.4;
  width: 200px;
  height: 200px;
  transform: rotate(90deg);
}
.patterns-1.sub-pattern-1 {
  inset-block-end: inherit;
  inset-block-start: 0;
}

.patterns-2 {
  inset-inline-end: 0;
  inset-block-start: -3px;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
  width: 200px;
  height: 200px;
  transform: rotate(270deg);
}

.patterns-3 {
  inset-inline-start: 8%;
  inset-block-start: 18%;
  z-index: -1;
  position: absolute;
  opacity: 0.15;
  width: 80px;
  height: 80px;
}
.patterns-3.sub-pattern-1 {
  inset-inline-start: 11%;
}
.patterns-3.sub-pattern-2 {
  inset-inline-start: 14%;
  inset-block-start: 25%;
}
.patterns-3.sub-pattern-3 {
  inset-inline-start: 14%;
  inset-block-start: 15%;
  width: 100px;
  height: 100px;
}

.patterns-4 {
  inset-inline-start: 8%;
  inset-block-start: 18%;
  z-index: -1;
  position: absolute;
  opacity: 0.5;
  width: 80px;
  height: 80px;
}
.patterns-4.sub-pattern-1 {
  inset-inline-start: 31.7%;
  inset-block-start: 34%;
  filter: invert(1);
  opacity: 0.3;
}
.patterns-4.sub-pattern-2 {
  inset-inline-start: 28.7%;
  inset-block-start: 28%;
  filter: invert(1);
  opacity: 0.2;
}
.patterns-4.sub-pattern-3 {
  inset-inline-start: 12%;
  inset-block-start: 19%;
  width: 150px;
  height: 150px;
  opacity: 0.2;
}

.patterns-5 {
  inset-inline-start: -3%;
  inset-block-end: -7%;
  z-index: -1;
  position: absolute;
  opacity: 0.3;
  width: 200px;
  height: 200px;
}

.patterns-6 {
  inset-inline-end: 0;
  inset-block-start: -7%;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
  width: 200px;
  height: 200px;
}

.patterns-7 {
  inset-inline-end: 0;
  inset-block-start: 0;
  z-index: -1;
  position: absolute;
  opacity: 0.1;
  width: 200px;
  height: 200px;
}

.patterns-8 {
  inset-inline-start: 0%;
  inset-block-end: 0%;
  z-index: -1;
  position: absolute;
  width: 160px;
  opacity: 0.3;
}
.patterns-8.sub-pattern-1 {
  inset-inline-end: 0;
  inset-inline-start: inherit;
  transform: rotate(270deg);
}
.patterns-8.sub-pattern-2 {
  opacity: 0.1;
  width: 400px;
  height: 150px;
  inset-block-start: 30%;
  inset-inline-start: -3%;
  transform: rotate(320deg);
}
.patterns-8.sub-pattern-3 {
  inset-inline-end: 0;
  inset-inline-start: inherit;
  transform: rotate(90deg);
}

.patterns-9 {
  inset-inline-end: 32%;
  inset-block-start: -11%;
  transform: rotate(45deg);
  z-index: -1;
  position: absolute;
  opacity: 0.3;
  width: 100px;
  height: 100px;
}
.patterns-9.sub-pattern-1 {
  inset-block-end: -11%;
  inset-block-start: inherit;
  inset-inline-start: 32%;
  inset-inline-end: inherit;
}
.patterns-9.sub-pattern-2 {
  inset-inline-end: -4%;
  inset-inline-start: auto;
  transform: rotate(0);
}

.patterns-10 {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 150px;
  height: 150px;
}

.patterns-11 {
  position: absolute;
  inset-inline-start: -6%;
  inset-block-start: 0;
  width: 100px;
  height: 100px;
}

.bg-pattern-1 {
  z-index: 1;
  position: relative;
}
.bg-pattern-1::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/patterns/28.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
  background-attachment: fixed;
}
.bg-pattern-1.bg-image1::before {
  background-image: url("../images/backgrounds/5.jpg");
  background-position: top;
  opacity: 0.3;
}
.bg-pattern-1.bg-image2::before {
  background-image: url("../images/backgrounds/6.jpg");
  opacity: 0.1;
}
.bg-pattern-1.bg-image3::before {
  background-image: url("../images/backgrounds/10.jpg");
  opacity: 0.03;
}
.bg-pattern-1.bg-image4::before {
  background-image: url("../images/backgrounds/13.jpg");
  opacity: 0.05;
}
.bg-pattern-1.bg-image5::before {
  background-image: url("../images/backgrounds/12.jpg");
  opacity: 0.05;
}
.bg-pattern-1.bg-image6::before {
  background-image: url("../images/patterns/29.jpg");
  background-position: bottom;
  opacity: 0.05;
}

.feature-client-bg {
  position: relative;
}
.feature-client-bg::before {
  content: "";
  position: absolute;
  inset-inline-start: -17%;
  inset-block-end: -5%;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: linear-gradient(to right, var(--primary01) 0%, transparent 100%);
  z-index: 0;
}
.feature-client-bg.feature-bg-1::before {
  width: 250px;
  height: 250px;
  inset-inline-start: inherit;
  inset-inline-end: -20%;
  background: linear-gradient(to left, var(--primary01) 0%, transparent 100%);
}
.feature-client-bg.feature-bg-2::before {
  width: 250px;
  height: 250px;
  inset-inline-start: inherit;
  inset-inline-end: -20%;
  background: linear-gradient(to left, var(--primary01) 0%, transparent 100%);
}

.br-bottom-before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: -10%;
  height: 5px;
  width: 200px;
  border-radius: 20%;
  transform: skew(10deg, 356deg);
  background: linear-gradient(to right, #85caff 0%, transparent 100%);
}

.feature-icon {
  padding: 13px;
  border-radius: 50%;
}
.feature-icon.secondary {
  background-color: rgba(var(--secondary-rgb), 0.2);
}
.feature-icon.success {
  background-color: rgba(var(--success-rgb), 0.2);
}
.feature-icon.primary {
  background-color: var(--primary02);
}
.feature-icon.danger {
  background-color: rgba(var(--danger-rgb), 0.2);
}

.heading-section {
  text-align: center;
  margin-block-end: 3rem;
}

.heading-title {
  font-size: 2rem;
  margin-block-end: 0.5rem;
  font-family: "Urbanist", sans-serif;
  color: var(--color-dark);
  font-weight: 700;
  line-height: 1.2;
}

.heading-subtitle {
  position: relative;
  display: inline-block;
  margin-block-end: 0.5rem;
}
.heading-subtitle::before, .heading-subtitle::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  inset-inline-start: -30px;
  background-color: rgb(var(--secondary-rgb));
  inset-block-start: 50%;
}
.heading-subtitle::after {
  inset-inline-end: -30px;
  inset-inline-start: inherit;
}

.heading-description {
  font-size: 1rem;
  margin-block-end: 0;
  color: var(--default-text-color);
  opacity: 0.8;
}

.counter-item {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 10px;
  margin-inline-end: 10px;
  position: relative;
  z-index: 1;
}
.counter-item::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/backgrounds/3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
  border-radius: 10px;
}
.counter-item h4,
.counter-item h5 {
  color: #fff;
}
.counter-item.offer-counter {
  border-radius: 50%;
  width: 20%;
  padding: 18px;
}
.counter-item.offer-counter::before {
  border-radius: 50%;
}
.counter-item.offer-counter .count_down {
  font-size: 26px;
  margin-block-end: 0;
}
.counter-item .count_down {
  font-size: 40px;
  margin-block-end: 0;
}

@media (max-width: 575px) {
  .counter-item {
    margin: 10px auto;
  }
  .counter-item.offer-counter {
    width: 70%;
    border-radius: 10px;
  }
  .counter-item.offer-counter::before {
    border-radius: 10px;
  }
  .accept-cookies {
    display: none !important;
  }
}
.main {
  justify-content: space-evenly;
}

.payment-cards {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.addons::before {
  content: "";
  position: absolute;
  inset-inline-start: 3%;
  inset-block-start: 15%;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: var(--primary005);
}

.blog-view {
  transition: all ease 0.3s;
}
.blog-view:hover .hover {
  margin-inline-start: 10px;
  transition: all ease 0.3s;
}

.price-offer {
  position: absolute;
  inset-block-start: 6%;
  background-size: cover;
  inset-inline-end: 27%;
  text-align: center;
  color: #fff;
  padding: 24px;
  height: 125px;
  background-image: url(../images/png/37.png);
  display: flex;
  align-items: center;
}

.table .hide {
  display: none;
}
.table .show {
  display: table-row;
}

.btn-toggle {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  outline: 0;
}
.btn-toggle[aria-expanded=true] {
  content: "Read Less";
}

.exchange-icon {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px 17px;
  border-radius: 50%;
}

.blog-reply {
  position: relative;
}
.blog-reply::before {
  content: "";
  background: var(--custom-white);
  width: 15px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
  inset-block-start: -9px;
  border-inline-start: 1px solid var(--border);
  border-block-start: 1px solid var(--border);
}

.banner-section {
  padding: 7rem 0 4rem 0;
}

@media (max-width: 991px) {
  .banner-section {
    padding: 4rem 0 4rem 0;
  }
}
@media (min-width: 992px) {
  .custom-page .app-sidebar {
    background-color: var(--primary09) !important;
  }
  .custom-page .sticky-pin .app-sidebar {
    background-color: var(--custom-white) !important;
  }
}
.auth-logo.logo-color {
  display: block;
}
.auth-logo.logo-dark {
  display: none;
}

.pricing-icon {
  width: 50px;
  height: 50px;
  margin-block-end: 1rem;
  background-color: var(--primary01);
  border-radius: 50px;
  padding: 0.7rem;
  box-shadow: 0px 5px 10px var(--primary01);
}

#offerModal .btn-close {
  background-color: var(--white-2);
  line-height: 1;
  padding: 5px;
  border-radius: 50px;
  position: absolute;
  inset-inline-end: 5%;
  filter: invert(0);
}
#offerModal .btn-close i {
  font-size: 25px;
}

.payment-options {
  background-color: var(--gray-200);
  padding: 10px 15px;
  border-radius: 7px;
  width: 100px;
  margin-block-end: 0.5rem;
}
.payment-options:hover {
  background-color: var(--primary01);
}

.custom-card-1 {
  position: relative;
  border: 1px solid var(--gray-300);
  border-radius: 0.625rem;
  box-shadow: 0 3px 10px 0 var(--black-05);
  background: var(--custom-white);
}
.custom-card-1::before {
  content: "";
  position: absolute;
  inset-block-start: -6px;
  inset-inline-start: 49%;
  inset-inline-end: 49%;
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-block-start-color: var(--gray-300);
  border-inline-start-color: var(--gray-300);
  transform: rotate(45deg);
  background-color: var(--custom-white);
  z-index: 901;
  display: block;
}

.custom-card-2 {
  position: relative;
  border: 1px solid var(--gray-300);
  border-radius: 0.625rem;
  box-shadow: 0 3px 10px 0 var(--black-05);
  background: var(--custom-white);
}
.custom-card-2::before {
  content: "";
  position: absolute;
  inset-block-end: -6px;
  inset-inline-start: 49%;
  inset-inline-end: 49%;
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-block-end-color: var(--gray-300);
  border-inline-end-color: var(--gray-300);
  transform: rotate(45deg);
  background-color: var(--custom-white);
  z-index: 901;
  display: block;
}

.list-group .list-group-item {
  background-color: var(--custom-white);
}

.app-img-white {
  display: none;
}

.video-banner::before {
  opacity: 1;
}
.video-banner .video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}

.main-header {
  background-color: var(--primary-color);
}
.main-header .nav-link {
  color: #fff;
  transition: all ease 0.2s;
}
.main-header .nav-link:hover, .main-header .nav-link.active {
  color: #fff;
  opacity: 1;
}

.desktop-logo.logo-color {
  display: none;
}

.sticky.sticky-pin {
  position: fixed;
  inset-block-start: 0;
  width: 100%;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.085);
  transition: all ease-in-out 0.3s;
  z-index: 999;
}
.sticky.sticky-pin .nav-link {
  color: var(--gray-600);
  opacity: 1;
}
.sticky.sticky-pin .header-nav-list > .nav-item:hover > .nav-link:not(.active), .sticky.sticky-pin .header-nav-list > .nav-item.active > .nav-link:not(.active) {
  color: var(--primary-color);
}
.sticky.sticky-pin .main-header {
  background-color: #fff;
}
.sticky.sticky-pin .main-nav-header {
  border-block-start: 1px solid var(--border);
  background-color: #fff;
}
.sticky.sticky-pin .mobile-dark {
  display: none;
}
.sticky.sticky-pin .header-link-icon {
  color: var(--gray-600);
  fill: var(--gray-600);
}
.sticky.sticky-pin .header-element {
  background-color: rgba(20, 87, 230, 0.05);
}
.sticky.sticky-pin .app-sidebar {
  background-color: var(--custom-white);
  border-block-start: 1px solid var(--border);
  box-shadow: 0 0.125rem 1rem 3px rgba(0, 0, 0, 0.1);
}
.sticky.sticky-pin .app-sidebar .side-menu__label {
  color: var(--menu-color);
  opacity: 1;
}
.sticky.sticky-pin .app-sidebar .btn-outline-light {
  border-color: var(--black-1);
  color: var(--color-dark);
}
.sticky.sticky-pin .app-sidebar .btn-outline-light:hover {
  background-color: var(--black-05);
}

@media (min-width: 992px) {
  .sticky.sticky-pin .slide-menu {
    border: 1px solid var(--border);
  }
}
@keyframes slide-down {
  from {
    transform: translateY(-300px);
  }
  to {
    transform: transform;
  }
}
/* Start::main-nav-header */
.main-nav-header {
  background-color: var(--black-1);
}
.main-nav-header .nav-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* End::main-nav-header */
/* Start::header-nav-list */
.header-nav-list .header__megamenu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 350px;
  background-color: #fff;
  inset-block-start: 100%;
  inset-inline-start: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  border-start-start-radius: 0px;
  border-start-end-radius: 0px;
  padding: 0.9375rem;
  transform: rotateX(-50deg);
  transform-origin: top;
  transition: transform ease 0.3s;
  z-index: 5;
}
.header-nav-list .header__megamenu.megamenu__md {
  width: 600px;
}
.header-nav-list .header__megamenu.megamenu__lg {
  width: 880px;
}
.header-nav-list .header__megamenu.megamenu__xl {
  width: 1000px;
  inset-inline-start: calc(-100% - 100px);
}
.header-nav-list .nav-link {
  padding: 18px 20px;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
}
.header-nav-list > .nav-item {
  position: relative;
}
.header-nav-list > .nav-item:hover > .nav-link:not(.active), .header-nav-list > .nav-item.active > .nav-link:not(.active) {
  color: #fff;
  opacity: 1;
}
.header-nav-list > .nav-item:hover .header__megamenu, .header-nav-list > .nav-item.active .header__megamenu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}

/* End::header-nav-list */
.header__dropmenu .header__dropitem {
  padding: 0.5rem 0rem;
  padding-inline-start: 1.5rem;
  position: relative;
}
.header__dropmenu .header__dropitem::after {
  content: "";
  height: 7px;
  width: 7px;
  background-color: var(--primary02);
  border-radius: 50%;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 50%;
  transform: translateY(-50%);
  transition: all ease 0.3s;
}
.header__dropmenu .header__dropitem:hover::after {
  background-color: var(--primary-color);
}
.header__dropmenu .header__dropitem:hover .header__dropavatar {
  box-shadow: -1px 5px 8px 0px rgba(50, 48, 48, 0.15);
}
.header__dropmenu.drop-style-none .header__dropitem {
  padding-inline-start: 0rem;
}
.header__dropmenu.drop-style-none .header__dropitem::after {
  content: none;
}

/* Start::main-brand-header */
.main-brand-header .brand-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px;
}

/* End::main-brand-header */
/* End::main-brand-header */
.top-menu {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.top-menu .top-menu-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
}
.top-menu .top-menu-brand .nav-link {
  color: #fff;
}

.avatar {
  position: relative;
  height: 2.625rem;
  width: 2.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  line-height: 0;
}
.avatar.avatar-xs {
  width: 1.5rem;
  height: 1.5rem;
}
.avatar.avatar-sm {
  width: 2rem;
  height: 2rem;
}
.avatar.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar.avatar-xl {
  width: 5.3rem;
  height: 5.3rem;
}

.team-avatar-left {
  position: absolute;
  inset-inline-start: 17px;
  background-color: #fff;
  inset-block-start: 24%;
  border: 1px solid var(--border);
  color: var(--muted);
  font-weight: 700;
  font-size: 12px;
}

.team-avatar-right {
  position: absolute;
  inset-inline-end: 17px;
  background-color: #fff;
  inset-block-start: 24%;
  border: 1px solid var(--border);
  color: var(--muted);
  font-weight: 700;
  font-size: 12px;
}

.choices__list--dropdown .choices__item--selectable:after {
  display: none !important;
}

.choices__list--dropdown .choices__item--selectable, [dir=rtl] .choices__list--dropdown .choices__item--selectable {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.choices__inner {
  background-color: var(--custom-white) !important;
  border-color: var(--border) !important;
  min-height: 42px !important;
  padding: 5.5px 7.5px 3.75px !important;
  border-radius: 0.3125rem !important;
}

.choices__list--dropdown,
.is-open .choices__list--dropdown {
  border-color: var(--border) !important;
}

.choices[data-type*=select-one] .choices__button {
  opacity: 0.3 !important;
}

.choices[data-type*=select-one]:after {
  inset-inline-end: 11.5px;
}

.choices[data-type*=select-one]:after {
  border-color: var(--black-6) transparent transparent transparent !important;
}

.choices[data-type*=select-one].is-open:after {
  border-color: transparent transparent var(--black-6) transparent !important;
}

.choices[data-type*=select-one] .choices__inner {
  padding-block-end: 5.5px;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.choices__list--dropdown {
  background-color: var(--custom-white) !important;
}

.testimonialSwiper .swiper {
  width: 100%;
  height: 100%;
}
.testimonialSwiper .swiper-slide {
  font-size: 18px;
  background: var(--primary-color);
  border-radius: 0.625rem;
}
.testimonialSwiper .swiper-slide .card {
  background-color: var(--primary-color);
  color: #fff;
}
.testimonialSwiper .swiper-slide img {
  display: block;
  object-fit: cover;
}
.testimonialSwiper .swiper-pagination-bullet {
  width: 0.8rem !important;
  height: 0.8rem !important;
  background: #fff !important;
  border: 3px solid var(--primary-color);
}

.homeSwiper .swiper-slide {
  background: transparent;
}
.homeSwiper .swiper-pagination {
  position: relative;
}
.homeSwiper .swiper-horizontal > .swiper-pagination-bullets, .homeSwiper .swiper-pagination-bullets.swiper-pagination-horizontal, .homeSwiper .swiper-pagination-custom, .homeSwiper .swiper-pagination-fraction {
  bottom: 0;
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 15px !important;
}

.swiper-button-next, .swiper-button-prev {
  color: #fff !important;
  border: 1px solid var(--white-2);
  background: var(--white-2);
  padding: 5px;
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 50px;
}

@media (min-width: 1700px) {
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 10rem !important;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 10rem !important;
  }
}
.switcher-icon {
  position: fixed;
  height: 45px;
  width: 45px;
  background: #000;
  color: #fff !important;
  border-start-start-radius: 0.3rem;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 0.3rem;
  inset-inline-end: 0;
  inset-block-start: 20rem;
  text-align: center;
  line-height: 3;
  z-index: 99;
}

/* Start::OffCanvas */
.offcanvas {
  background-color: var(--custom-white);
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

/* End::OffCanvas */
/* Start::Switcher */
#switcher-canvas {
  width: 350px;
}
#switcher-canvas .offcanvas-body {
  padding: 0 0 3.125rem 0;
}
#switcher-canvas .canvas-footer {
  padding: 0.75rem 1.563rem;
  position: absolute;
  inset-block-end: 0;
  width: 100%;
  background-color: var(--custom-white);
  box-shadow: var(--shadow);
}
#switcher-canvas .pickr .pcr-button {
  border-radius: 50%;
}
#switcher-canvas .pickr .pcr-button::before, #switcher-canvas .pickr .pcr-button::after {
  border-radius: 50%;
}

.switcher-style {
  padding: 0.875rem 1.563rem;
}
.switcher-style h6 {
  margin-block-end: 0.625rem;
}

.switch-select .form-check-label {
  font-size: 0.813rem;
}
.switch-select.form-check {
  margin-block-end: 0;
  min-height: auto;
}

.theme-colors.switcher-style {
  padding: 0.938rem 1.563rem;
}
.theme-colors .switch-select .color-input {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.188rem solid var(--border);
}
.theme-colors .switch-select .color-input.color-white {
  background-color: #fff;
}
.theme-colors .switch-select .color-input.color-white.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.05) !important;
}
.theme-colors .switch-select .color-input.color-dark {
  background-color: rgb(var(--dark-rgb));
}
.theme-colors .switch-select .color-input.color-dark.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(14, 16, 20, 0.2);
}
.theme-colors .switch-select .color-input.color-primary {
  background-color: var(--primary-color);
}
.theme-colors .switch-select .color-input.color-primary.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(var(--primary-rgb), 0.2);
}
.theme-colors .switch-select .color-input.color-primary-1 {
  background-color: rgb(51, 102, 255);
}
.theme-colors .switch-select .color-input.color-primary-1.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(51, 102, 255, 0.2);
}
.theme-colors .switch-select .color-input.color-primary-2 {
  background-color: rgb(16, 173, 231);
}
.theme-colors .switch-select .color-input.color-primary-2.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(16, 173, 231, 0.2);
}
.theme-colors .switch-select .color-input.color-primary-3 {
  background-color: rgb(125, 172, 48);
}
.theme-colors .switch-select .color-input.color-primary-3.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(125, 172, 48, 0.2);
}
.theme-colors .switch-select .color-input.color-primary-4 {
  background-color: rgb(209, 68, 141);
}
.theme-colors .switch-select .color-input.color-primary-4.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(209, 68, 141, 0.2);
}
.theme-colors .switch-select .color-input.color-primary-5 {
  background-color: rgb(108, 62, 179);
}
.theme-colors .switch-select .color-input.color-primary-5.form-check-input:checked[type=radio] {
  box-shadow: 0 0 0 0.1rem rgba(108, 62, 179, 0.2);
}
.theme-colors .switch-select .color-input.color-bg-1 {
  background-color: rgb(50, 62, 93);
}
.theme-colors .switch-select .color-input.color-bg-2 {
  background-color: rgb(81, 93, 50);
}
.theme-colors .switch-select .color-input.color-bg-3 {
  background-color: rgb(79, 50, 93);
}
.theme-colors .switch-select .color-input.color-bg-4 {
  background-color: rgb(50, 87, 93);
}
.theme-colors .switch-select .color-input.color-bg-5 {
  background-color: rgb(93, 50, 50);
}
.theme-colors .switch-select .form-check-input:checked[type=radio] {
  background-image: none;
}
.theme-colors .switch-select .form-check-input:focus {
  box-shadow: none;
}
.theme-colors .switch-select .form-check-input:active {
  filter: brightness(100%);
}

.switcher-style-head {
  font-size: 0.95rem;
  font-weight: 500;
  margin-block-end: 0;
  padding: 0.313rem 0.625rem;
  color: var(--color-dark);
}
.switcher-style-head .switcher-style-description {
  float: var(--float-end);
  font-size: 0.625rem;
  background-color: rgba(var(--secondary-rgb), 0.2);
  color: rgb(var(--secondary-rgb));
  padding: 0.125rem 0.313rem;
  border-radius: 0.3rem;
}

.theme-container-primary button,
.theme-container-background button {
  display: none;
}

.pickr-container-primary .pickr .pcr-button::after,
.pickr-container-background .pickr .pcr-button::after {
  content: "\f4b0";
  font-family: "bootstrap-icons" !important;
  color: rgba(255, 255, 255, 0.7);
  line-height: 2;
}

.form-check-input {
  background-color: var(--custom-white);
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

/* End::Switcher */
/* ######## LAYOUT-STYLES ######## */
[data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
  display: flex !important;
}
[data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
  margin-block-end: 0 !important;
  margin-inline-end: 0.35rem !important;
}

@media (min-width: 992px) {
  .menu-hover .app-sidebar, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar {
    width: 9rem;
  }
  .menu-hover .app-sidebar .main-sidebar, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }
  .menu-hover .app-sidebar .main-sidebar-header, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .main-sidebar-header {
    width: 9rem;
  }
  .menu-hover .app-sidebar .side-menu__icon, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }
  .menu-hover .app-sidebar .slide, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide {
    padding: 0;
  }
  .menu-hover .app-sidebar .side-menu__angle, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .side-menu__angle {
    display: none !important;
  }
  .menu-hover .app-sidebar .slide-menu .side-menu__angle, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu .side-menu__angle, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu .side-menu__angle {
    display: block !important;
  }
  .menu-hover .app-sidebar .slide-menu.child1, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child1, .menu-hover .app-sidebar .slide-menu.child2, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child2, .menu-hover .app-sidebar .slide-menu.child3, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 {
    min-width: 12rem;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item, .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item, .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item:before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before, .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item:before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before, .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item:before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
    inset-block-start: 0.938rem;
    inset-inline-start: 0.75rem;
  }
}
@media (min-width: 992px) and (hover: hover) and (pointer: fine) {
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 {
    display: block !important;
  }
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
    display: block !important;
  }
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .menu-hover .app-sidebar .side-menu__item, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .side-menu__item,
  .menu-hover .app-sidebar .side-menu__label,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 9rem !important;
    background: var(--custom-white);
    inset-block-start: auto !important;
    box-shadow: 0.125rem 0.063rem 0.5rem var(--black-1);
    transition: none !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2 {
    inset-inline-start: 12.9rem !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2::before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2::before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2::before {
    display: none;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 11.9rem !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3::before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3::before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3::before {
    display: none;
  }
  .menu-hover .simplebar-content-wrapper, [data-nav-style=menu-hover][data-nav-layout=horizontal] .simplebar-content-wrapper, [data-nav-style=menu-hover][toggled=menu-hover-closed] .simplebar-content-wrapper {
    position: initial;
  }
  .menu-hover .simplebar-mask, [data-nav-style=menu-hover][data-nav-layout=horizontal] .simplebar-mask, [data-nav-style=menu-hover][toggled=menu-hover-closed] .simplebar-mask {
    position: inherit;
  }
  .menu-hover .simplebar-placeholder, [data-nav-style=menu-hover][data-nav-layout=horizontal] .simplebar-placeholder, [data-nav-style=menu-hover][toggled=menu-hover-closed] .simplebar-placeholder {
    height: auto !important;
  }
  .menu-hover .app-header, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-header, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-header {
    padding-inline-start: 9rem;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2 {
    inset-inline-start: auto !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2::before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2::before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2::before {
    display: none;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-start: auto !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3::before, [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3::before, [data-nav-style=menu-hover][toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3::before {
    display: none;
  }
}

@media (min-width: 992px) {
  [data-nav-layout=horizontal] {
    /* horizontal arrows */
  }
  [data-nav-layout=horizontal] .main-menu {
    display: flex;
    transition: all 0.5s ease;
  }
  [data-nav-layout=horizontal] .main-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  [data-nav-layout=horizontal] .app-header {
    padding-inline-start: 0 !important;
    z-index: 101;
  }
  [data-nav-layout=horizontal] .app-content {
    margin-inline-start: 0rem !important;
  }
  [data-nav-layout=horizontal] .app-content > .container-fluid {
    width: 94%;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
  [data-nav-layout=horizontal] .app-sidebar {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-layout=horizontal] .app-sidebar .simplebar-mask {
    overflow: visible;
  }
  [data-nav-layout=horizontal] .main-sidebar .simplebar-vertical {
    visibility: hidden !important;
  }
  [data-nav-layout=horizontal] .main-sidebar,
  [data-nav-layout=horizontal] .main-header-container {
    width: 94%;
    margin: 0 auto;
  }
  [data-nav-layout=horizontal] .head_menu_container {
    height: 141.5px;
    background-color: var(--primary-color);
  }
  [data-nav-layout=horizontal] .horizontal-logo {
    padding: 0.85rem;
    display: block;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-dark,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-logo,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-nav-layout=horizontal] .sidemenu-toggle {
    display: none;
  }
  [data-nav-layout=horizontal] .sticky.sticky-pin {
    animation-name: slide-down;
    animation-duration: 0.5s;
    animation-timing-function: ease;
  }
  [data-nav-layout=horizontal] .sticky.sticky-pin .side-menu__item.active, [data-nav-layout=horizontal] .sticky.sticky-pin .side-menu__item:hover {
    color: var(--primary-color);
  }
  [data-nav-layout=horizontal] .sticky.sticky-pin .side-menu__item.active .side-menu__label, [data-nav-layout=horizontal] .sticky.sticky-pin .side-menu__item:hover .side-menu__label {
    color: var(--primary-color);
    opacity: 1;
  }
  [data-nav-layout=horizontal] .app-sidebar {
    width: 100% !important;
    height: 3.55rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__label {
    color: rgba(255, 255, 255, 0.8);
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__item.active, [data-nav-layout=horizontal] .app-sidebar .side-menu__item:hover {
    color: var(--primary-color);
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__item.active .side-menu__label, [data-nav-layout=horizontal] .app-sidebar .side-menu__item:hover .side-menu__label {
    color: #fff;
    opacity: 1;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__item.active .side-menu__icon, [data-nav-layout=horizontal] .app-sidebar .side-menu__item:hover .side-menu__icon {
    fill: var(--primary-color);
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 li,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 li,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 li {
    padding-inline-start: 0rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__item:before {
    inset-block-start: 1rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
    height: 3.55rem !important;
    padding: 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-inline-start: 1.5rem !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2, [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-end: 100% !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.mega-slide-menu.active {
    inset-inline-start: 0 !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
    position: absolute !important;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    display: flex;
  }
  [data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.25rem;
    margin-block-end: 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    background-color: var(--custom-white);
    inset-block-start: 100% !important;
    box-shadow: 1px 3px 8px var(--black-1);
    min-width: 12rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide {
    padding: 0;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu::before {
    content: "";
    position: absolute;
    inset-block-start: -7px;
    inset-inline-start: 10%;
    width: 13px;
    height: 13px;
    border: 1px solid transparent;
    border-block-start-color: var(--border);
    border-inline-start-color: var(--border);
    transform: rotate(45deg);
    background-color: var(--custom-white);
    z-index: 901;
    display: block;
    transition-duration: 0.25s;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.mega-slide-menu-threefr::before {
    inset-inline-start: 4%;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.mega-slide-menu-twofr::before {
    inset-inline-start: 7%;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.mega-slide-menu::before {
    inset-inline-start: 26%;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1, [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2, [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 {
    border-radius: 0.25rem;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.has-sub, [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.has-sub, [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.has-sub {
    width: 100%;
  }
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub {
    display: inline-grid;
    position: static;
  }
  [data-nav-layout=horizontal] .app-sidebar .main-menu {
    margin-block-end: 0;
  }
  [data-nav-layout=horizontal] .main-sidebar-header {
    display: none;
  }
  [data-nav-layout=horizontal] .main-sidebar {
    margin-block-start: 0;
    padding-block-end: 3rem;
  }
  [data-nav-layout=horizontal] .slide__category {
    display: none;
  }
  [data-nav-layout=horizontal] .simplebar-content {
    padding: 0 !important;
    overflow: hidden;
  }
  [data-nav-layout=horizontal] .simplebar-content-wrapper {
    overflow: visible !important;
    height: auto;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left {
    inset-inline-start: 23px;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    position: absolute;
    inset-block-start: 9px;
    padding: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--border);
    border-radius: 50px;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    position: absolute;
    inset-block-start: 5px;
    padding: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--border);
    border-radius: 50px;
    background: #fff;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    inset-inline-end: -3%;
  }
  [data-nav-layout=horizontal] .main-menu-container .slide-left {
    inset-inline-start: -3%;
  }
  [data-nav-layout=horizontal] .mega-slide-menu {
    width: auto !important;
    max-width: 100% !important;
  }
  [data-nav-layout=horizontal] .mega-slide-menu-twofr {
    width: auto !important;
    max-width: 50% !important;
  }
  [data-nav-layout=horizontal] .mega-slide-menu-threefr {
    width: auto !important;
    max-width: 75% !important;
  }
  [data-nav-layout=horizontal] .mega-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0.9375rem 1.3rem !important;
  }
  [data-nav-layout=horizontal] .mega-menu-onefr {
    display: grid;
    padding: 0.9375rem 1.3rem !important;
  }
  [data-nav-layout=horizontal] .mega-menu-twofr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.9375rem 1.3rem !important;
  }
  [data-nav-layout=horizontal] .mega-menu-threefr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0.9375rem 1.3rem !important;
  }
  [data-nav-layout=horizontal] .mega-menu-onefr {
    width: 350px;
  }
  [data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar,
  [data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar {
    box-shadow: -1px 5px 8px 0px rgba(50, 48, 48, 0.15);
  }
  [data-nav-layout=horizontal] .header-element {
    display: none;
  }
  [data-nav-layout=horizontal] .app-toggle-header {
    display: none;
  }
}
[data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 ul,
[data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 ul,
[data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 ul {
  padding: 0;
}
[data-nav-layout=horizontal] .menu-label {
  position: relative;
}
[data-nav-layout=horizontal] .menu-label:before {
  content: "";
  height: 1px;
  width: 50px;
  background-color: rgb(var(--secondary-rgb));
  border-radius: 50%;
  position: absolute;
  inset-block-end: -15%;
  transition: all ease 0.3s;
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-primary-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-primary-transparent {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary05);
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-secondary-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-secondary-transparent {
  background-color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-info-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-info-transparent {
  background-color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-success-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-success-transparent {
  background-color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-warning-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-warning-transparent {
  background-color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-danger-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-danger-transparent {
  background-color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-pink-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-pink-transparent {
  background-color: rgb(var(--pink-rgb));
  border: 1px solid rgb(var(--pink-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-purple-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-purple-transparent {
  background-color: rgb(var(--purple-rgb));
  border: 1px solid rgb(var(--purple-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar.bg-teal-transparent,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar.bg-teal-transparent {
  background-color: rgb(var(--teal-rgb));
  border: 1px solid rgb(var(--teal-rgb));
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar svg,
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar path,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar svg,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar path {
  fill: #fff;
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar i,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar i {
  color: #fff;
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar .backup-icon line,
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar .backup-icon path,
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar .backup-icon polyline,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar .backup-icon line,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar .backup-icon path,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar .backup-icon polyline {
  stroke: #fff;
  fill: none;
}
[data-nav-layout=horizontal] .side-menu__item:hover .header__dropavatar .cloud-icon,
[data-nav-layout=horizontal] .side-menu__item.active .header__dropavatar .cloud-icon {
  fill: none;
}

/* Responsive Menu Styles */
@media (max-width: 991.98px) {
  [toggled=close] .app-sidebar {
    inset-inline-start: -19rem;
  }
  [toggled=close] .app-header {
    padding-inline-start: 0rem;
  }
  [toggled=close] .app-header .horizontal-logo {
    display: block;
  }
  [toggled=close] .close-toggle {
    display: none;
    color: #fff;
  }
}

@media (max-width: 991.98px) {
  [toggled=open] .app-sidebar {
    inset-inline-start: 0rem;
  }
  [toggled=open] .app-header {
    padding-inline-start: 0rem;
  }
  [toggled=open] .app-header .horizontal-logo {
    display: block;
  }
  [toggled=open] .close-toggle {
    display: block;
    color: #fff;
  }
  [toggled=open] .header-element {
    background-color: var(--gray-200);
    z-index: 101;
  }
  [toggled=open] .header-element .header-link-icon {
    color: var(--default-text-color);
  }
  [toggled=open] .app-toggle-header {
    display: flex;
    align-items: center;
    justify-content: center;
    inset-block-start: 16px;
    height: 85px;
    border-block-end: 1px solid var(--border);
  }
  [toggled=open] .app-toggle-header .brand-main {
    margin-inline-start: 10px;
  }
  [toggled=open] .app-toggle-header .brand-main .desktop-logo.logo-dark {
    display: none;
  }
  [toggled=open] .app-toggle-header .brand-main .desktop-logo.logo-color {
    display: block;
  }
}

@media (min-width: 575.98px) {
  .mobile-logo {
    display: none;
  }
  .sticky.sticky-pin .desktop-logo.logo-color {
    display: block;
  }
  .sticky.sticky-pin .desktop-logo.logo-dark {
    display: none;
  }
}
@media (max-width: 575px) {
  .mobile-logo {
    display: block;
  }
  .desktop-logo,
  .mobile-color {
    display: none;
  }
  .sticky.sticky-pin .mobile-logo.mobile-color {
    display: block;
  }
}
/* Responsive Menu Styles */
.main-sidebar {
  margin-block-start: 3.72rem;
  padding-block-start: 0.5rem;
  padding-block-end: 5rem;
  height: 100vh;
  position: relative;
  overflow: auto;
}

.app-sidebar {
  width: 18rem;
  height: 100%;
  position: fixed;
  inset-inline-start: 0;
  z-index: 100;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  transition: all 320ms ease;
  font-family: "Urbanist", sans-serif;
}
.app-sidebar .main-sidebar-header {
  height: 3.75rem;
  width: 15rem;
  position: fixed;
  display: flex;
  background: var(--primary09);
  z-index: 9;
  align-items: center;
  justify-content: center;
  padding: 0.813rem 1.25rem;
  border-inline-end: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
  transition: all 320ms ease;
}
.app-sidebar .main-sidebar-header .header-logo img {
  height: 2rem;
  line-height: 2rem;
}
.app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}
.app-sidebar .main-sidebar-header .header-logo .desktop-dark,
.app-sidebar .main-sidebar-header .header-logo .toggle-logo,
.app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: none;
}
.app-sidebar .slide__category {
  color: var(--primary-color);
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.065rem;
  text-transform: uppercase;
  padding: 0.75rem 1.65rem;
  white-space: nowrap;
  opacity: 0.5;
  position: relative;
}
.app-sidebar .slide {
  margin: 0;
  padding: 0rem 0.625rem;
}
.app-sidebar .side-menu__item {
  padding: 1.3rem 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.84rem;
  color: var(--default-text-color);
  font-weight: 500;
}
.app-sidebar .side-menu__item.active, .app-sidebar .side-menu__item:hover {
  color: var(--primary-color);
}
.app-sidebar .side-menu__item.active:before, .app-sidebar .side-menu__item:hover:before {
  border: 1px solid var(--primary-color) !important;
}
.app-sidebar .side-menu__item.active .side-menu__label, .app-sidebar .side-menu__item:hover .side-menu__label {
  opacity: 1;
}
.app-sidebar .side-menu__item.active .side-menu__icon, .app-sidebar .side-menu__item:hover .side-menu__icon {
  fill: var(--primary-color);
}
.app-sidebar .side-menu__item.active h6, .app-sidebar .side-menu__item:hover h6 {
  color: var(--primary-color);
}
.app-sidebar .slide-menu {
  padding: 0rem;
}
.app-sidebar .slide-menu.child1 .slide {
  padding: 0.6rem 1.6rem;
}
.app-sidebar .slide-menu.child2 .slide, .app-sidebar .slide-menu.child3 .slide {
  padding: 0.4rem 1.6rem;
}
.app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon {
  padding-block-start: 0.6rem;
  padding-block-end: 0.5rem;
}
.app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .child2,
.app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .child3 {
  padding-block-start: 0.6rem;
  padding-block-end: 0.5rem;
}
.app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .slide:hover, .app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .slide.active {
  color: var(--primary-color);
}
.app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .slide:hover:before, .app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .slide.active:before {
  background-color: rgb(var(--secondary-rgb));
}
.app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon li {
  padding-inline-start: 1.3rem;
  position: relative;
}
.app-sidebar .slide-menu.child2 .slide:before, .app-sidebar .slide-menu.child3 .slide:before {
  inset-block-start: 14px !important;
}
.app-sidebar .slide-menu i {
  margin-inline-end: 0.8rem;
  font-size: 1rem;
}
.app-sidebar .side-menu__label {
  white-space: nowrap;
  position: relative;
  font-size: 16px;
  line-height: 1;
  vertical-align: middle;
}
.app-sidebar .side-menu__icon {
  margin-inline-end: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;
  color: var(--primary-color);
  fill: var(--primary-color);
}
.app-sidebar .side-menu__angle {
  transform-origin: center;
  font-size: 0.85rem;
  transition: all 320ms ease;
}
.app-sidebar .slide.side-menu__label1 {
  display: none;
}

.horizontal-logo {
  padding: 0.85rem 0rem;
  display: none;
}

@media (max-width: 991.98px) {
  .app-sidebar {
    background-color: var(--custom-white);
    inset-inline-start: -19rem;
  }
  .top-menu {
    display: none;
  }
  .horizontal-logo .header-logo .desktop-logo,
  .horizontal-logo .header-logo .desktop-dark,
  .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }
  .horizontal-logo .header-logo .toggle-logo {
    display: block;
  }
  .app-sidebar .slide-menu.child1 .slide {
    padding: 0.6rem 0.6rem;
  }
  .app-sidebar .slide-menu.child2 .slide, .app-sidebar .slide-menu.child3 .slide {
    padding: 0.4rem 0.6rem;
  }
  .app-sidebar .side-menu__item {
    padding: 1rem 0.25rem;
  }
  .menu-label {
    margin-inline-start: 10px;
    margin-block-start: 10px;
  }
  .menu-label i {
    font-size: 16px;
  }
  .menu-label span {
    font-size: 13px;
  }
  .app-sidebar {
    border-inline-end: 1px solid var(--border);
  }
}
.slide.has-sub .slide-menu {
  transform: translate(0, 0) !important;
  visibility: visible !important;
}

.nav ul li {
  list-style-type: none;
}

.nav > ul {
  padding-inline-start: 0px;
}

.slide-menu {
  display: none;
}

.slide.has-sub {
  display: grid;
}
.slide.has-sub.open > .side-menu__item .side-menu__angle {
  transform: rotate(180deg);
}
.slide.has-sub.open .slide.has-sub.open > .side-menu__item .side-menu__angle {
  transform: rotate(270deg);
}
.slide.has-sub .side-menu__item {
  justify-content: space-between;
}
.slide.has-sub .slide .side-menu__item {
  justify-content: flex-start;
}
.slide.has-sub .slide.has-sub .side-menu__item {
  justify-content: space-between;
}

/* Responsive Styles Start */
@media (max-width: 991.98px) {
  .main-content {
    padding-block-start: 3.75rem;
  }
  .main-header {
    z-index: 100;
    position: fixed;
    inset-block-start: 0;
    inset-inline: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    transition: all 320ms ease;
  }
  .main-header.sticky-header {
    box-shadow: 0 0.125rem 1rem 3px rgba(0, 0, 0, 0.1);
  }
  .main-sidebar-header {
    display: none !important;
  }
  .main-sidebar {
    margin-block-start: 0;
  }
  .header-element {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    padding: 5px 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .brand-main .desktop-logo {
    height: 2.5rem;
  }
  .main-menu-container {
    display: block;
  }
  .main-menu-container .login-btn {
    border-color: var(--border);
    color: var(--default-text-color);
  }
  .sidemenu-toggle {
    display: flex;
  }
  .app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon .slide:before {
    inset-inline-start: 9px;
    inset-block-start: 17px;
  }
  .app-sidebar .slide-menu.child1.mega-slide-menu-onefr.without-icon li {
    padding-inline-start: 1.6rem;
  }
}
.slide-left,
.slide-right {
  display: none;
}

.header-link-icon {
  font-size: 20px;
  color: #fff;
  fill: #fff;
  transition: all ease 0.3s;
}

.slide-menu .side-menu__item {
  padding: 0;
}

.header__dropavatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.5rem;
  flex: 0 0 2.5rem;
}

.badge {
  line-height: 0;
  padding: 8px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 50px;
}

.badge-lg {
  font-size: 12px;
  padding: 11px;
}

.blink-text {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  50% {
    opacity: 0.6;
  }
}
.toggle-menu {
  display: flex;
}

/* Responsive Styles End */
/* ######## SWITCHER-STYLES ######## */
@media (max-width: 991.98px) {
  [data-theme-mode=dark][toggled=open] .app-toggle-header .brand-main .desktop-logo.logo-dark {
    display: block;
  }
  [data-theme-mode=dark][toggled=open] .app-toggle-header .brand-main .desktop-logo.logo-color {
    display: none;
  }
}
[data-theme-mode=dark] .bg-pattern-1.bg-image1::before {
  opacity: 0.07;
}
[data-theme-mode=dark] .card .tool-img-white {
  display: block;
  opacity: 0.8;
}
[data-theme-mode=dark] .card .tool-img {
  display: none;
}
[data-theme-mode=dark] .bg-gray-custom {
  background-color: var(--gray-100);
}
[data-theme-mode=dark] .feature-card-13 {
  background: var(--custom-white) url(../images/png/73.png) 95% 95%/auto no-repeat scroll;
  background-size: 20% 32%;
}
[data-theme-mode=dark] .pricing-icon {
  background-color: var(--primary04);
  box-shadow: 0px 5px 10px var(--primary02);
}
[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}
[data-theme-mode=dark] .auth-logo.logo-color {
  display: none;
}
[data-theme-mode=dark] .auth-logo.logo-dark {
  display: block;
}
[data-theme-mode=dark] .bg-light {
  background-color: var(--default-background) !important;
}
[data-theme-mode=dark] .custom-bg::before {
  opacity: 0.05;
}
[data-theme-mode=dark] .modal-content {
  background-color: var(--custom-white);
}
[data-theme-mode=dark] .modal-header {
  border-bottom: 1px solid var(--border);
}
[data-theme-mode=dark] .btn-close {
  filter: invert(1);
}
[data-theme-mode=dark] .app-img-white {
  display: block;
  margin: 0 auto;
}
[data-theme-mode=dark] .app-img {
  display: none;
}
[data-theme-mode=dark] .dark-invert {
  filter: invert(1);
}
[data-theme-mode=dark] .dark-opacity1 {
  opacity: 0.1;
}

[dir=rtl] [type=email], [dir=rtl] [type=number], [dir=rtl] [type=tel], [dir=rtl] [type=url] {
  direction: rtl;
}
@media (min-width: 992px) {
  [dir=rtl][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    right: 0;
    left: inherit !important;
  }
  [dir=rtl][data-nav-layout=horizontal] .app-sidebar .slide-menu::before {
    transform: rotate(-45deg);
  }
}
[dir=rtl] .ribbone span {
  transform: rotate(45deg);
}
[dir=rtl] .patterns-7 {
  transform: rotate(270deg);
}
[dir=rtl] .patterns-8.sub-pattern-1 {
  transform: rotate(0deg);
}
[dir=rtl] .patterns-2 {
  transform: rotate(180deg);
}
[dir=rtl] .patterns-8, [dir=rtl] .patterns-6 {
  transform: rotate(270deg);
}
[dir=rtl] .transform-rotate-180 {
  transform: rotate(90deg);
}
[dir=rtl] .categories-dropdowns ul::before {
  transform: rotate(-45deg);
}
[dir=rtl] .feature-card-5::after {
  transform: translateX(50%);
}
[dir=rtl] .patterns-1 {
  transform: rotate(0deg);
}
[dir=rtl] .banner-arrow-down::after {
  transform: translateX(50%) rotate(-45deg);
}
[dir=rtl] .feature-card-7::before {
  transform: translateX(50%) translateZ(-1px);
}
[dir=rtl] .feature-card-7::after {
  transform: translateX(50%) translateZ(-2px);
}
[dir=rtl] .custom-card-1:before, [dir=rtl] .custom-card-2:before {
  transform: rotate(-45deg);
}
[dir=rtl] .blog-reply::before {
  transform: rotate(-45deg);
}
[dir=rtl] .patterns-8.sub-pattern-3 {
  transform: rotate(180deg);
}
[dir=rtl] .choices {
  direction: rtl;
}
[dir=rtl] .choices[data-type*=select-one]:after {
  left: 11.5px;
  right: inherit;
}
[dir=rtl] .choices[data-type*=select-one] .choices__button {
  right: inherit;
  left: 0;
  margin-left: 25px;
  margin-right: inherit;
}
[dir=rtl] .footer-icon .list-item::before, [dir=rtl] .fe-chevron-right, [dir=rtl] .fe-arrow-right, [dir=rtl] .bi-arrow-right::before {
  transform: rotate(180deg);
}
[dir=rtl] .bi-chevron-right::before {
  content: "\f284";
}

@media (min-width: 1400px) {
  [data-width=boxed] body {
    background-color: var(--default-body-bg-color);
  }
  [data-width=boxed] .container {
    max-width: 1200px;
  }
  [data-width=boxed] .page {
    width: 85%;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 1rem var(--black-1);
  }
  [data-width=boxed] .page .app-header {
    width: 85%;
    margin: 0 auto;
  }
  [data-width=boxed] .page .app-sidebar {
    inset-inline-start: auto;
  }
  [data-width=boxed][data-nav-layout=horizontal] .app-sidebar {
    width: 85% !important;
  }
  [data-width=boxed][page-style=classic] .page {
    background-color: var(--custom-white);
  }
  [data-width=boxed][page-style=classic] .card {
    box-shadow: none;
  }
}

/* ######## UTILITIES ######## */
/* Start::font sizes */
.tx-11 {
  font-size: 0.6875rem;
}

.tx-12 {
  font-size: 0.7875rem;
}

.tx-13 {
  font-size: 0.8125rem;
}

.tx-14 {
  font-size: 0.875rem;
}

.tx-15 {
  font-size: 0.9375rem;
}

.tx-16 {
  font-size: 1rem;
}

.tx-17 {
  font-size: 1.15rem;
}

.tx-18 {
  font-size: 1.125rem;
}

.tx-20 {
  font-size: 1.25rem;
}

.tx-22 {
  font-size: 1.319rem !important;
}

.tx-24 {
  font-size: 1.44rem;
}

.tx-26 {
  font-size: 1.56rem;
}

.tx-28 {
  font-size: 1.68rem;
}

.tx-50 {
  font-size: 3rem;
}

/* End::font sizes */
/* Start::text colors */
.tx-muted {
  color: var(--muted);
}

.tx-primary {
  color: var(--primary-color);
}

.tx-secondary {
  color: rgb(var(--secondary-rgb));
}

.tx-success {
  color: rgb(var(--success-rgb));
}

.tx-danger {
  color: rgb(var(--danger-rgb));
}

.tx-info {
  color: rgb(var(--info-rgb));
}

.tx-purple {
  color: rgb(var(--purple-rgb));
}

.tx-gray-600 {
  color: var(--gray-600);
}

.tx-default {
  color: var(--default-text-color);
}

.tx-color-default {
  color: var(--color-dark);
}

.tx-pink {
  color: rgb(var(--pink-rgb));
}

.tx-teal {
  color: rgb(var(--teal-rgb));
}

.tx-warning {
  color: rgb(var(--warning-rgb));
}

.tx-warning-light {
  color: #f4d714;
}

.tx-success-light {
  color: #78f7b2;
}

/* End::text colors */
.text-bg-primary {
  background-color: var(--primary-color) !important;
}

.text-bg-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
}

.text-bg-success {
  background-color: rgb(var(--success-rgb)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: rgb(var(--warning-rgb)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: rgb(var(--info-rgb)) !important;
}

.tx-info-dark {
  color: #85caff;
}

.font-variant-caps {
  font-variant: small-caps;
}

/* Start::links */
a {
  text-decoration: none;
  color: var(--default-text-color);
}
a:hover {
  --bs-text-opacity: 1;
  opacity: 1;
  color: var(--primary-color);
}

/* End::links */
/* Start::font-weights */
.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

/* End::font-weights */
.content-1 {
  font-size: 50px;
  line-height: 1.3;
}

@media (max-width: 991px) {
  .content-1 {
    font-size: 40px;
  }
}
.content-2 {
  font-size: 1.165rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Urbanist", sans-serif;
  color: var(--color-dark);
  font-weight: 600;
}

.text-shadow {
  text-shadow: 0px 5px #0b2459;
}

/* Start::background color */
.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
}

.bg-success {
  background-color: rgb(var(--success-rgb)) !important;
}

.bg-pink {
  background-color: rgb(var(--pink-rgb)) !important;
}

.bg-warning {
  background-color: rgb(var(--warning-rgb)) !important;
}

.bg-danger {
  background-color: rgb(var(--danger-rgb)) !important;
}

/* End::background color */
/* Start::gray set */
.bg-gray-100 {
  background-color: var(--gray-100);
}

.bg-gray-200 {
  background-color: var(--gray-200);
}

/* End::gray set */
/* Start::background transparent */
.bg-primary-transparent {
  background-color: var(--primary02) !important;
  border: 1px solid var(--primary05);
}

.bg-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.2);
  border: 1px solid rgba(var(--secondary-rgb), 0.5);
}

.bg-info-transparent {
  background-color: rgba(var(--info-rgb), 0.2);
  border: 1px solid rgba(var(--info-rgb), 0.5);
}

.bg-success-transparent {
  background-color: rgba(var(--success-rgb), 0.2);
  border: 1px solid rgba(var(--success-rgb), 0.5);
}

.bg-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.2);
  border: 1px solid rgba(var(--warning-rgb), 0.5);
}

.bg-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.2);
  border: 1px solid rgba(var(--danger-rgb), 0.5);
}

.bg-pink-transparent {
  background-color: rgba(var(--pink-rgb), 0.2);
  border: 1px solid rgba(var(--pink-rgb), 0.5);
}

.bg-purple-transparent {
  background-color: rgba(var(--purple-rgb), 0.2);
  border: 1px solid rgba(var(--purple-rgb), 0.5);
}

.bg-teal-transparent {
  background-color: rgba(var(--teal-rgb), 0.2);
  border: 1px solid rgba(var(--teal-rgb), 0.5);
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.2);
}

/* End::background transparent */
/* Start::background gradient */
.bg-primary-gradient {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--blue-rgb)) 100%);
}

.bg-secondary-gradient {
  background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, rgba(var(--secondary-rgb), 0.5) 100%);
}

.bg-success-gradient {
  background: linear-gradient(to right, rgb(var(--success-rgb)) 0%, rgba(var(--success-rgb), 0.5) 100%);
}

.bg-warning-gradient {
  background: linear-gradient(to right, rgb(var(--warning-rgb)) 0%, rgba(var(--warning-rgb), 0.5) 100%);
}

.bg-pink-gradient {
  background: linear-gradient(to right, rgb(var(--pink-rgb)) 0%, rgba(var(--pink-rgb), 0.5) 100%);
}

.bg-danger-gradient {
  background: linear-gradient(to right, rgb(var(--danger-rgb)) 0%, rgba(var(--danger-rgb), 0.5) 100%);
}

.bg-info-gradient {
  background: linear-gradient(to right, rgb(var(--info-rgb)) 0%, rgba(var(--info-rgb), 0.5) 100%);
}

.bg-purple-gradient {
  background: linear-gradient(to right, rgb(var(--purple-rgb)) 0%, rgba(var(--purple-rgb), 0.5) 100%);
}

.bg-teal-gradient {
  background: linear-gradient(to right, rgb(var(--teal-rgb)) 0%, rgba(var(--teal-rgb), 0.5) 100%);
}

.bg-orange-gradient {
  background: linear-gradient(to right, rgb(var(--orange-rgb)) 0%, rgba(var(--orange-rgb), 0.5) 100%);
}

.bg-gray-transparent {
  background-color: #dfecfa;
}

.bg-gray-custom {
  background-color: #f8fbfe;
}

.bg-gray-gradient {
  background: linear-gradient(to right, #739dd3 0%, rgb(157, 190, 229) 100%);
}

.bg-blue-gradient {
  background: linear-gradient(to right, rgb(var(--blue-rgb)) 0%, rgba(var(--blue-rgb), 0.5) 100%);
}

.bg-white-1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-white-2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-primary-01 {
  background-color: var(--primary01) !important;
}

.bg-primary-01 {
  background-color: var(--primary01) !important;
}

.bg-primary-02 {
  background-color: var(--primary02) !important;
}

.bg-primary-03 {
  background-color: var(--primary03) !important;
}

.bg-primary-04 {
  background-color: var(--primary04) !important;
}

.bg-primary-05 {
  background-color: var(--primary05) !important;
}

.bg-primary-06 {
  background-color: var(--primary06) !important;
}

.bg-primary-07 {
  background-color: var(--primary07) !important;
}

.bg-primary-08 {
  background-color: var(--primary08) !important;
}

.bg-primary-09 {
  background-color: var(--primary09) !important;
}

.bg-radial-gradient {
  background: radial-gradient(circle, rgb(13, 202, 240) 20%, var(--primary08) 100%);
}

.bg-primary-radial-gradient {
  background: radial-gradient(circle, rgb(13, 202, 240) 0%, rgb(20, 87, 230) 100%);
}

.bg-linkedin {
  background: #0985c7;
  color: #fff;
}
.bg-linkedin:hover {
  background: #0985c7;
  color: #fff;
  opacity: 0.9;
}

/* End::background gradient */
/* Start::minimum width */
.min-w-fit-content {
  min-width: fit-content;
}

.w-12p {
  width: 12% !important;
}

.w-20p {
  width: 20%;
}

.w-33p {
  width: 33%;
}

@media (min-width: 992px) {
  .w-lg-12p {
    width: 12% !important;
  }
}
/* End::minimum width */
/* Start::opacity */
.op-1-1 {
  opacity: 1;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

/* End::opacity */
.ms-30p {
  margin-inline-start: 30% !important;
}

.ms--80p {
  margin-inline-start: -80%;
}

.mt--14p {
  margin-block-start: -14%;
}

.mt--19p {
  margin-block-start: -19%;
}

.mt--30p {
  margin-block-start: -30%;
}

@media (min-width: 578px) {
  .ms-sm-30p {
    margin-inline-start: 30% !important;
  }
  .ms-sm--20p {
    margin-inline-start: -20%;
  }
}
@media (min-width: 1200px) {
  .ms-xl--20p {
    margin-inline-start: -20%;
  }
}
@media (min-width: 992px) {
  .mt-lg--13p {
    margin-block-start: -13%;
  }
}
.ms--20p {
  margin-inline-start: -20%;
}

.mb--10p {
  margin-block-end: -10%;
}

.banner-pd-1 {
  padding: 30px 0px;
}

.banner-pd-2 {
  padding: 70px 0px;
}

.banner-pd-3 {
  padding: 50px 0px;
}

.p-6 {
  padding: 3.5rem;
}

.px-6 {
  padding-inline-start: 3.5rem;
  padding-inline-end: 3.5rem;
}

.py-6 {
  padding-block-start: 3.5rem;
  padding-block-end: 3.5rem;
}

@media (min-width: 576px) {
  .p-sm-6 {
    padding: 3.5rem;
  }
  .px-sm-6 {
    padding-inline-start: 3.5rem;
    padding-inline-end: 3.5rem;
  }
  .py-sm-6 {
    padding-block-start: 3.5rem;
    padding-block-end: 3.5rem;
  }
}
@media (min-width: 768px) {
  .p-md-6 {
    padding: 3.5rem;
  }
  .px-md-6 {
    padding-inline-start: 3.5rem;
    padding-inline-end: 3.5rem;
  }
  .py-md-6 {
    padding-block-start: 3.5rem;
    padding-block-end: 3.5rem;
  }
}
@media (min-width: 992px) {
  .p-lg-6 {
    padding: 3.5rem;
  }
  .px-lg-6 {
    padding-inline-start: 3.5rem;
    padding-inline-end: 3.5rem;
  }
  .py-lg-6 {
    padding-block-start: 3.5rem;
    padding-block-end: 3.5rem;
  }
}
@media (min-width: 1200px) {
  .p-xl-6 {
    padding: 3.5rem;
  }
  .px-xl-6 {
    padding-inline-start: 3.5rem;
    padding-inline-end: 3.5rem;
  }
  .py-xl-6 {
    padding-block-start: 3.5rem;
    padding-block-end: 3.5rem;
  }
}
@media (min-width: 1400px) {
  .p-xxl-6 {
    padding: 3.5rem;
  }
  .px-xxl-6 {
    padding-inline-start: 3.5rem;
    padding-inline-end: 3.5rem;
  }
  .py-xxl-6 {
    padding-block-start: 3.5rem;
    padding-block-end: 3.5rem;
  }
}
/* Start::border radius */
.br-0 {
  border-radius: 0;
}

.br-1 {
  border-radius: 0.0625rem;
}

.br-3 {
  border-radius: 0.1875rem;
}

.br-5 {
  border-radius: 0.3125rem;
}

.br-7 {
  border-radius: 0.4375rem;
}

.br-10 {
  border-radius: 0.625rem;
}

.br-50 {
  border-radius: 50px;
}

.br-50p {
  border-radius: 50%;
}

.br-bottom-right-radius-0 {
  border-end-end-radius: 0 !important;
}

.br-bottom-left-radius-0 {
  border-end-start-radius: 0 !important;
}

.br-top-left-radius-0 {
  border-start-start-radius: 0 !important;
}

.br-top-right-radius-0 {
  border-start-end-radius: 0 !important;
}

/* End::border radius */
.border {
  border: var(--border-width) solid var(--border) !important;
}

.border-top {
  border-block-start: var(--border-width) solid var(--border) !important;
}

.border-bottom {
  border-block-end: var(--border-width) solid var(--border) !important;
}

.border-start {
  border-inline-start: var(--border-width) solid var(--border) !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}

.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}

.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}

.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}

.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}

.border-purple {
  border-color: rgb(var(--purple-rgb)) !important;
}

.border-teal {
  border-color: rgb(var(--teal-rgb)) !important;
}

.border-pink {
  border-color: rgb(var(--pink-rgb)) !important;
}

.border-light {
  border-color: var(--gray-400) !important;
}

.border-white-2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-white-1 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-5 {
  --border-width: 0.3125rem;
}

.border-3 {
  --border-width: 0.1875rem;
}

.shadow-none {
  box-shadow: none;
}

.all-ease-03 {
  transition: all ease 0.3s;
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.13s;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.reveal {
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease;
}
.reveal.reveal-active {
  opacity: 1;
  transform: translateY(0px);
}

.plain-link {
  color: var(--primary-color);
  opacity: 0.8;
  text-decoration: underline;
}

.muted-content {
  color: var(--muted);
}

.primary-shadow {
  box-shadow: 0 0.5rem 1rem var(--primary02);
}

.secondary-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--secondary-rgb), 0.2);
}

.success-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--success-rgb), 0.2);
}

.info-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--info-rgb), 0.2);
}

.pink-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--pink-rgb), 0.2);
}

.purple-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--purple-rgb), 0.2);
}

.danger-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--danger-rgb), 0.2);
}

.teal-shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--teal-rgb), 0.2);
}

.shadow-1 {
  box-shadow: 1.5rem 1.5rem 1rem rgb(201, 219, 235);
}

.br-style {
  border-start-start-radius: 50% 10%;
  border-start-end-radius: 20% 40%;
  border-end-end-radius: 50% 10%;
  border-end-start-radius: 20% 40%;
}

.br-style1 {
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-end-radius: 50%;
  border-end-start-radius: 0;
}

.pink-inset-shadow {
  box-shadow: inset 0rem 0rem 1.5rem 2rem rgba(var(--pink-rgb), 0.15);
}

.primary-inset-shadow {
  box-shadow: inset 0rem 0rem 1.5rem 2rem var(--primary01);
}

.teal-inset-shadow {
  box-shadow: inset 0rem 0rem 1.5rem 2rem rgba(var(--teal-rgb), 0.2);
}

.purple-inset-shadow {
  box-shadow: inset 0rem 0rem 1.5rem 2rem rgba(var(--purple-rgb), 0.15);
}

.secondary-inset-shadow {
  box-shadow: inset 0rem 0rem 1.5rem 2rem rgba(var(--secondary-rgb), 0.15);
}

.right-10p {
  inset-inline-end: 10%;
}

.top-7p {
  inset-block-start: 7%;
}

.right-0 {
  inset-inline-end: 0 !important;
}

.height-inherit {
  height: inherit;
}

.left-auto {
  inset-inline-start: auto;
}

.left-7p {
  inset-inline-start: 7%;
}

.top-14p {
  inset-block-start: 14%;
}

.bottom-3p {
  inset-block-end: -3%;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index--1 {
  z-index: -1;
}

.transform-scale {
  transition-duration: 0.3s;
}
.transform-scale:hover {
  transform: scale(1.05);
}

.transform-rotate-45 {
  transform: rotate(45deg);
}

.transform-rotate--45 {
  transform: rotate(-45deg);
}

.transform-rotate-90 {
  transform: rotate(90deg);
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.transform-skewX-10 {
  transform: skewX(10deg);
}

.transform-skewX--10 {
  transform: skewX(-10deg);
}

.filter-shadow {
  filter: drop-shadow(2px 4px 6px var(--black-3));
}

.filter-shadow-1 {
  filter: drop-shadow(16px 6px 0px var(--black-3));
}

.filter-invert {
  filter: invert(1);
}

.bottom--4r {
  bottom: -4rem;
}